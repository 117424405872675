import { Card, Button, Badge } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

const Project = ({ title, img, colorStatus, dataPoz, dataNeg, index }) => {
  return (
    <div className="col">
      <Card className="shadow-sm h-100 project__card_body text-light">
        <NavLink to={`/projectView/` + index}>
          <Card.Img src={img} className="bd-placeholder-img card-img-top" width="100%" height="225" alt={title} />
        </NavLink>
        <Card.Body>
          <Card.Text>{title}</Card.Text>
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
              <NavLink to="/projectEdit">
                <Button variant="outline-secondary" className="btn btn-primary">
                  Изменить
                </Button>
              </NavLink>
            </div>
            <small className="text-body-secondary">
              <Badge className="text-bg-secondary bg-success">{`${dataPoz}/${dataNeg}`}</Badge>
            </small>
            <small className="text-body-secondary">
              <Badge className={`text-bg-secondary ${colorStatus} p-1 border border-light rounded-circle`}> </Badge>
            </small>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Project;
