import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <div className="container">
            <footer className="py-5 text-light">
                <div className="row">
                    <div className="col-6 col-md-2 mb-3">
                        <h5>Исследования</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0 text-body-secondary">Обзор</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0 text-body-secondary">Статьи</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0 text-body-secondary">О нас пишут</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0 text-body-secondary">Особенности</NavLink></li>

                            {/* <li className="nav-item mb-2"><NavLink to="/pricing" className="nav-link p-0 text-body-secondary">Pricing</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/pricingEdit" className="nav-link p-0 text-body-secondary">pricingEdit</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/usersPage" className="nav-link p-0 text-body-secondary">usersPage</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/userProfilePage" className="nav-link p-0 text-body-secondary">userProfilePage</NavLink></li> */}

                        </ul>
                    </div>

                    <div className="col-6 col-md-2 mb-3">
                        <h5>Сервис AML</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0 text-body-secondary">Обзор</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0 text-body-secondary">Подписка</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0 text-body-secondary">Часто задаваемые вопросы</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0 text-body-secondary">Попробуйте AML</NavLink></li>
                        </ul>
                    </div>

                    <div className="col-6 col-md-2 mb-3">
                        <h5>Компания</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><NavLink to="/contacts" className="nav-link p-0 text-body-secondary">О нас</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0 text-body-secondary">Блог</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0 text-body-secondary">Карьера</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0 text-body-secondary">Истории клиентов</NavLink></li>
                            <li className="nav-item mb-2"><NavLink to="/contacts" className="nav-link p-0 text-body-secondary">Контакты</NavLink></li>
                        </ul>
                    </div>

                    <div className="col-md-5 offset-md-1 mb-3">
                        <form>
                            <h5>Подписаться на нашу рассылку</h5>
                            <p className="text-body-secondary" >Ежемесячный дайджест новых и интересных событий от нас</p>
                            <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                                <label htmlFor="newsletter1" className="visually-hidden">Адрес электронной почты</label>
                                <input id="newsletter1" type="text" className="form-control me-3" placeholder="example@mail.com" />
                            </div>
                            <button className="btn btn-primary" type="button">Подписаться</button>
                        </form>
                    </div>
                </div>

                <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                    <p>&copy; ООО "ГравиЛинк", 2023-2024 Все права защищены.</p>
                    <ul className="list-unstyled d-flex">
                        <li className="ms-3"><a className="link-body-emphasis" href="#"></a></li>
                        <li className="ms-3"><a className="link-body-emphasis" href="#"></a></li>
                        <li className="ms-3"><a className="link-body-emphasis" href="#"></a></li>
                    </ul>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
