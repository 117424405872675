import React, { useState } from 'react';
import { Row, Col, Form, Alert } from 'react-bootstrap';

const ProfileSubscribe = () => {
  const [subscribeEnabled, setSubscribeEnabled] = useState(false);
  const [digestFrequency, setDigestFrequency] = useState('daily');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const toggleSubscribe = () => {
    setSubscribeEnabled(!subscribeEnabled);
    setShowSuccessAlert(true);
  };

  const handleDigestFrequencyChange = (e) => {
    setDigestFrequency(e.target.value);
  };

  return (
    <>
      <Row className='bg-dark text-dark'>
        <div className='text-light'>
          <h2>Рассылка по электронной почте</h2>
          <p className='opacity-75'>Будте в курсе событий на вашем аккаунте GraviLink. Рассылки отправляются дополнительно к индивидуальным уведомлениям по электронной почте о событиях.</p>
        </div>
      </Row>
      <Row className="profile-subscribe-container bg-dark text-light">
        <Col sm={12}>
          <p>Выберите частоту получения рассылки по электронной почте:</p>
          <Form>
            <Form.Group controlId="formDigestFrequency">
              <Form.Control as="select" value={digestFrequency} onChange={handleDigestFrequencyChange}>
                <option value="daily">Ежедневно</option>
                <option value="weekly">Еженедельно</option>
                <option value="monthly">Ежемесячно</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formSubscribe">
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Подписаться на рассылку по электронной почте"
                checked={subscribeEnabled}
                onChange={toggleSubscribe}
              />
            </Form.Group>
          </Form>
          {showSuccessAlert && (
            <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
              Вы успешно {subscribeEnabled ? 'подписались на рассылку по электронной почте' : 'отписались от рассылки по электронной почте'}.
            </Alert>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProfileSubscribe;
