import { useState, useContext } from 'react';
import { AuthContext } from './userService';
import config from './config';

const ProjectPatch = () => {
    const { handleProjectPatch } = useContext(AuthContext);
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [error, setError] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [status, setStatus] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleId = (e) => {
        setId(e.target.value);
    };

    const handleReadUser = async () => {
        if (!id) {
            setError('Пожалуйста, введите id');
            return;
        }

        try {
            const response = await handleProjectPatch(id);
            setResponseMessage(response);
            setStatus(response);
            setError(null);
        } catch (error) {
            if (error.response) {
                const { data, status, headers } = error.response;
                console.error(data);
            } else if (error.request) {
                console.error(error.request);
            } else {
                console.error(error.message);
            }
            console.error(error.config);
        }
    };


    return (
        <div>
            <h2>Сделать проект публичным</h2>
            <input type="text" value={id} onChange={handleId} placeholder="ProjectId" />
            <button onClick={handleReadUser}>Ок</button>
            <div>Статус: {status}</div>
            {error && <div>{error}</div>}
        </div>
    );
};

export default ProjectPatch;
