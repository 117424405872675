//import project01 from "./../img/projects/project_0.png";
import project01Big from "./../img/projects/1200x400.png";
//import project01 from "./../img/projects/project-02.jpg";
import project01 from "./../img/projects/265x225.png";

const projects = [
  {
    key: 1,
    title: 'Медицина. Поиск вируса',
    description: 'Проект в области искусственного интеллекта, направленный на поиск и анализ вирусов в медицинских данных.',
    status: 'Empty',
    dataPoz: 123,
    dataNeg: 100,
    img: project01,
    imgBig: project01Big,
    colorStatus: 'bg-warning',  // Код для серого цвета
    gitHubLink: '',
    currentModels: [
      'Модель 1 от 10.01.2023 (64%)',
      'Модель 2 от 15.02.2023 (72%)',
      'Модель 3 от 20.03.2023 (80%)',
      'Модель 4 от 25.04.2023 (88%)',
      'Модель 5 от 30.05.2023 (96%)',
    ],
    isTrash: false,
  },
  {
    key: 2,
    title: 'Финансы. Анализ рынка',
    description: 'Проект по использованию искусственного интеллекта для анализа финансового рынка и предсказания трендов.',
    status: 'InProgress',
    dataPoz: 150,
    dataNeg: 75,
    img: project01,
    imgBig: project01Big,
    colorStatus: 'bg-success',  // Код для зеленого цвета
    gitHubLink: '',
    currentModels: [
      'Модель 1 от 10.01.2023 (69%)',
      'Модель 2 от 15.02.2023 (78%)',
      'Модель 3 от 20.03.2023 (86%)',
      'Модель 4 от 25.04.2023 (92%)',
      'Модель 5 от 30.05.2023 (98%)',
    ],
    isTrash: false,
  },
  {
    key: 3,
    title: 'Образование. Персонализированное обучение',
    description: 'Проект, целью которого является создание системы искусственного интеллекта для персонализированного обучения в образовательных учреждениях.',
    status: 'Completed',
    dataPoz: 200,
    dataNeg: 50,
    img: project01,
    imgBig: project01Big,
    colorStatus: 'bg-warning',  // Код для серого цвета
    gitHubLink: '',
    currentModels: [
      'Модель 1 от 10.01.2023 (73%)',
      'Модель 2 от 15.02.2023 (82%)',
      'Модель 3 от 20.03.2023 (88%)',
      'Модель 4 от 25.04.2023 (94%)',
      'Модель 5 от 30.05.2023 (99%)',
    ],
    isTrash: false,
  },
  {
    key: 4,
    title: 'Транспорт. Управление трафиком',
    description: 'Проект, направленный на оптимизацию управления транспортным потоком с использованием искусственного интеллекта.',
    status: 'Empty',
    dataPoz: 100,
    dataNeg: 80,
    img: project01,
    imgBig: project01Big,
    colorStatus: 'bg-warning',  // Код для серого цвета
    gitHubLink: '',
    currentModels: [
      'Модель 1 от 10.01.2023 (67%)',
      'Модель 2 от 15.02.2023 (76%)',
      'Модель 3 от 20.03.2023 (84%)',
      'Модель 4 от 25.04.2023 (90%)',
      'Модель 5 от 30.05.2023 (97%)',
    ],
    isTrash: false,
  },
  {
    key: 5,
    title: 'Промышленность. Предиктивное обслуживание',
    description: 'Проект, целью которого является внедрение системы предиктивного обслуживания в промышленности с применением искусственного интеллекта.',
    status: 'InProgress',
    dataPoz: 120,
    dataNeg: 90,
    img: project01,
    imgBig: project01Big,
    colorStatus: 'bg-warning',  // Код для серого цвета
    gitHubLink: '',
    currentModels: [
      'Модель 1 от 10.01.2023 (71%)',
      'Модель 2 от 15.02.2023 (80%)',
      'Модель 3 от 20.03.2023 (87%)',
      'Модель 4 от 25.04.2023 (93%)',
      'Модель 5 от 30.05.2023 (99%)',
    ],
    isTrash: false,
  },
  {
    key: 6,
    title: 'Сельское хозяйство. Оптимизация урожая',
    description: 'Проект, использующий искусственный интеллект для оптимизации процессов сельского хозяйства и увеличения урожайности.',
    status: 'Empty',
    dataPoz: 90,
    dataNeg: 60,
    img: project01,
    imgBig: project01Big,
    colorStatus: 'bg-success',
    gitHubLink: '',
    currentModels: [
      'Модель 1 от 10.01.2023 (68%)',
      'Модель 2 от 15.02.2023 (77%)',
      'Модель 3 от 20.03.2023 (85%)',
      'Модель 4 от 25.04.2023 (91%)',
      'Модель 5 от 30.05.2023 (98%)',
    ],
    isTrash: true,
  },
  {
    key: 7,
    title: 'Технологии. Инновационные разработки',
    description: 'Проект, направленный на создание инновационных технологий с применением искусственного интеллекта.',
    status: 'InProgress',
    dataPoz: 180,
    dataNeg: 70,
    img: project01,
    imgBig: project01Big,
    colorStatus: 'bg-success',
    gitHubLink: '',
    currentModels: [
      'Модель 1 от 10.01.2023 (72%)',
      'Модель 2 от 15.02.2023 (81%)',
      'Модель 3 от 20.03.2023 (88%)',
      'Модель 4 от 25.04.2023 (94%)',
      'Модель 5 от 30.05.2023 (99%)',
    ],
    isTrash: true,
  },
  {
    key: 8,
    title: 'Энергетика. Управление энергопотреблением',
    description: 'Проект, целью которого является оптимизация управления энергопотреблением с использованием искусственного интеллекта.',
    status: 'Empty',
    dataPoz: 110,
    dataNeg: 85,
    img: project01,
    imgBig: project01Big,
    colorStatus: 'bg-success',
    gitHubLink: '',
    currentModels: [
      'Модель 1 от 10.01.2023 (69%)',
      'Модель 2 от 15.02.2023 (78%)',
      'Модель 3 от 20.03.2023 (86%)',
      'Модель 4 от 25.04.2023 (93%)',
      'Модель 5 от 30.05.2023 (98%)',
    ],
    isTrash: true,
  },
];

export { projects };
