import $api from './../api/api';
import config from './config';

const JWTStorage = () => {
    let inMemoryJWT = null;
    let refreshTimeoutId = null;

    const refreshToken = (expiration) => {
        const timeoutTrigger = expiration - 10000;

        refreshTimeoutId = setTimeout(() => {
            $api.post("/refresh")
                .then((res) => {
                    const { accessToken, accessTokenExpiration } = res.data;
                    setToken(accessToken, accessTokenExpiration);
                })
                .catch(console.error);
        },
            timeoutTrigger);
    };

    const abortRefreshToken = () => {
        if (refreshTimeoutId) {
            clearTimeout(refreshTimeoutId);
        }
    };

    const getToken = () => {
        const token = localStorage.getItem(config.TOKEN);
        inMemoryJWT = token;
        //console.log("token:" + token);
        return inMemoryJWT;
    };

    const setToken = (token, tokenExpiration) => {
        localStorage.setItem(config.TOKEN, token);
        inMemoryJWT = token;
        //refreshToken(tokenExpiration);
    };

    const deleteToken = () => {
        inMemoryJWT = null;
        abortRefreshToken();
        localStorage.setItem(config.LOGOUT_STORAGE_KEY, Date.now());
        localStorage.setItem(config.TOKEN, "logout");
    };

    return {
        getToken,
        setToken,
        deleteToken,
    };
};

export default JWTStorage();
