import React, { useState } from 'react';
import { Row, Col, Image, Form, Dropdown } from 'react-bootstrap';
import defaultAvatar from "./../../img/default_avatar.jpg";

const ProfileEdit = () => {
  const [avatar, setAvatar] = useState(defaultAvatar);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setAvatar(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const [selectedCountry, setSelectedCountry] = useState(null); // Состояние для выбранной страны
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };

  return (
    <>
      <Row className='bg-dark text-light'>
        <div className='text-light'>
          <h2>Редактировать профиль</h2>
          <p className='opacity-75'>Заполните информацию о себе, чтобы она появилась в результатах поиска</p>
        </div>
        <Col sm={5} className="mt-4">
          <Form className="needs-validation" noValidate onSubmit={''}>
            <Row className="g-3">
              <Col sm={12}>
                <Form.Group controlId="firstName">
                  <Form.Label>* Имя Фамилия</Form.Label>
                  <Form.Control type="text" placeholder="Иван Иванов" required />
                  <Form.Control.Feedback type="invalid">Valid first name is required.</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={12}>
                <Form.Group controlId="nickName">
                  <Form.Label>Никнейм</Form.Label>
                  <Form.Control type="text" placeholder="ИИ" required />
                  <Form.Control.Feedback type="invalid">Valid first name is required.</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={12}>
                <Form.Group controlId="city">
                  <Form.Label>Город</Form.Label>
                  <Form.Control type="text" placeholder="Москва" required />
                  <Form.Control.Feedback type="invalid">Please enter a valid email address for shipping updates.</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={12}>
                <Form>
                  <Form.Group controlId="country">
                    <Form.Label>Страна</Form.Label>
                    <Dropdown onSelect={handleCountrySelect}>
                      <Dropdown.Toggle variant="" id="dropdown-country" className="text-light">
                        {selectedCountry ? selectedCountry : 'Выберите страну'}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-dark text-light">
                        <Dropdown.Item eventKey="Россия" className="bg-dark text-light">Россия</Dropdown.Item>
                        <Dropdown.Item eventKey="США" className="bg-dark text-light">Китай</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col sm={7} >
          <div className="d-flex justify-content-center mt-4">
            <Image src={avatar} alt="Ruslan Savinov" className="rounded" width="200" height="200" />
          </div>
          <div className="d-flex justify-content-center mt-4">
            <label className="btn btn-primary">
              Изменить аватар
              <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} />
            </label>
          </div>
        </Col>
        <button className="btn btn-primary mt-4">Сохранить изменения</button>
      </Row>
    </>
  );
};

export default ProfileEdit;
