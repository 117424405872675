import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import './style.css';

const ScrollToTopButton = () => {
    const [visible, setVisible] = useState(false);
  
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 100) {
            setVisible(true);
        } else if (scrolled <= 100) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        const currentPosition = window.scrollY; // Получаем текущую координату Y страницы
        console.log('координата Y страницы:', currentPosition);

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);

        return () => {
            window.removeEventListener('scroll', toggleVisible);
        };
    }, []);

    return (
        <div
            className={`scroll-to-top-button ${visible ? 'visible' : ''}`}
            onClick={scrollToTop}
        >
            {visible && <FaArrowUp />}
        </div>
    );
};

export default ScrollToTopButton;
