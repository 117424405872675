import React from 'react';
import { Link } from 'react-router-dom';

const AllLinksPage = () => {
    return (
        <div>
            <h1>All Links</h1>
            <ul>
                <li><Link to="/login">Login</Link></li>
                <li><Link to="/profile">Profile</Link></li>
                <li><Link to="/registration">Registration</Link></li>
                <li><Link to="/userComponent">User Component</Link></li>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/notifications">Notifications</Link></li>
                <li><Link to="/projects">Projects</Link></li>
                <li><Link to="/projectCreate">Create Project</Link></li>
                <li><Link to="/projectEdit">Edit Project</Link></li>
                <li><Link to="/projectView/1">View Project 1</Link></li>
                <li><Link to="/projectTrash">Projects Trash</Link></li>
                <li><Link to="/gprojects">GProjects</Link></li>
                <li><Link to="/covid">Covid</Link></li>
                <li><Link to="/contacts">Contacts</Link></li>
                <li><Link to="/pricing">Pricing</Link></li>
                <li><Link to="/pricingEdit">Edit Pricing</Link></li>
                <li><Link to="/billing">Billing</Link></li>
                <li><Link to="/usersPage">Users Page</Link></li>
                <li><Link to="/userProfilePage">User Profile Page</Link></li>
            </ul>
        </div>
    );
}

export default AllLinksPage;
