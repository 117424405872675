import React, { useState } from 'react';
import { Nav, NavItem, NavLink, Container, Row, Col, Image } from 'react-bootstrap';
import { FaUserEdit, FaFileAlt, FaUserFriends, FaUserCog, FaBell, FaLock, FaRegNewspaper, FaMoneyBillAlt } from 'react-icons/fa';
import ProfileEdit from '../profile/ProfileEdit';
import ProfileResume from '../profile/ProfileResume';
import ProfileSocial from '../profile/ProfileSocial';
import ProfileAccount from '../profile/ProfileAccount';
import ProfileNotifications from '../profile/ProfileNotifications';
import ProfileTwoFactorAuth from '../profile/ProfileTwoFactorAuth';
import ProfileSubscribe from '../profile/ProfileSubscribe';
import ProfileBilling from '../profile/ProfileBilling';
import defaultAvatar from "./../../img/default_avatar.jpg";

const Profile = () => {
  const [activeTab, setActiveTab] = useState('edit');

  const handleSelect = (selectedKey) => {
    setActiveTab(selectedKey);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'edit':
        return <ProfileEdit />;
      case 'resume':
        return <ProfileResume />;
      case 'social':
        return <ProfileSocial />;
      case 'account':
        return <ProfileAccount />;
      case 'notifications':
        return <ProfileNotifications />;
      case 'twoFactorAuth':
        return <ProfileTwoFactorAuth />;
      case 'subscribe':
        return <ProfileSubscribe />;
      case 'billing':
        return <ProfileBilling />;
      default:
        return null;
    }
  };

  return (
    <>
      <Container className="bg-light text-light body__bg_color">
        <Row>
          <Col sm={3} >
            <div className="settings-profile d-md-flex align-items-center text-center text-md-left">
              <Image src={defaultAvatar} alt="Ruslan Savinov" className="rounded" width="98" height="98" />
              <div class="settings-profile-info">
                <div className="text-justify-left">НАСТРОЙКИ</div>
                <div class="name">
                  <a class="name-link" target="_blank" href="https://gitea.gravilink.org/">Иван Иванов</a>
                </div>
                <div class="meta">С нами с 2024 г</div>
              </div>
            </div>
            <Nav className="flex-column list-group-item list-group-item-action d-flex gap-0 py-3" variant="pills" activeKey={activeTab} onSelect={handleSelect}>
              <NavItem>
                <NavLink eventKey="edit" className="profile__item"><p className="pt-2"><FaUserEdit className="mx-2" />Профиль</p></NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="resume" className="profile__item"><p className="pt-2"><FaFileAlt className="mx-2" />Резюме</p></NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="social" className="profile__item"><p className="pt-2"><FaUserFriends className="mx-2" />Соцсети</p></NavLink>
              </NavItem>
              <hr className="my-1" /> {/* line */}
              <NavItem>
                <NavLink eventKey="account" className="profile__item"><p className="pt-2"><FaUserCog className="mx-2" />Учетная запись</p></NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="notifications" className="profile__item"><p className="pt-2"><FaBell className="mx-2" />Уведомления</p></NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="twoFactorAuth" className="profile__item"><p className="pt-2"><FaLock className="mx-2" />Двухфакторная авторизация</p></NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="subscribe" className="profile__item"><p className="pt-2"><FaRegNewspaper className="mx-2" />Подписки</p></NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="billing" className="profile__item"><p className="pt-2"><FaMoneyBillAlt className="mx-2" />Счета и карты</p></NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col sm={9}>
            <div className="mt-3 bg-dark text-light">
              {renderTabContent()}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
