import React, { useState } from 'react';
import { Container, Table, Button, Form, Dropdown, Modal } from 'react-bootstrap';
import {
  FaUser,
  FaUserCog,
  FaEdit,
  FaTrash,
  FaCheck,
  FaTimes,
  FaUserPlus,
  FaPlus,
  FaKey,
} from 'react-icons/fa';

const rolesData = [
  {
    id: 1,
    name: 'Admin',
    permissions: ['read', 'write', 'delete'],
    description: 'Administrator role',
  },
  {
    id: 2,
    name: 'User',
    permissions: ['read'],
    description: 'Regular user role',
  },
  {
    id: 3,
    name: 'User1',
    permissions: ['read'],
    description: 'Additional user role 1',
  },
  {
    id: 4,
    name: 'User2',
    permissions: ['read'],
    description: 'Additional user role 2',
  },
  {
    id: 5,
    name: 'User3',
    permissions: ['read'],
    description: 'Additional user role 3',
  },
];

const defaultUsers = [
  {
    id: 1,
    type: 'admin',
    userName: 'admin',
    firstName: 'Admin',
    lastName: 'User',
    email: 'admin@example.com',
    online: true,
    role: 'Admin',
    password: 'admin_password',
    isEditing: false,
    iconColor: 'blue',
  },
  {
    id: 2,
    type: 'user',
    userName: 'john_doe',
    firstName: 'John',
    lastName: 'Doe',
    email: 'john.doe@example.com',
    online: false,
    role: 'User',
    password: 'user_password',
    isEditing: false,
    iconColor: 'black',
  },
];

const defaultRole = {
  id: rolesData.length + 1,
  name: '',
  permissions: [],
  description: '',
  isEditing: true,
};

const UsersPage = () => {
  const [users, setUsers] = useState(defaultUsers);
  const [newUser, setNewUser] = useState({
    id: defaultUsers.length + 1,
    type: 'user',
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    role: 'empty',
    password: '',
    online: false,
    isEditing: true,
    iconColor: 'black',
  });

  const [showRolesModal, setShowRolesModal] = useState(false);
  const [roles, setRoles] = useState(rolesData);
  const [newRole, setNewRole] = useState(defaultRole);
  const handleEdit = (userId) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, isEditing: !user.isEditing } : user
      )
    );
  };

  const handleSaveChanges = (userId) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, isEditing: false } : user
      )
    );
  };

  const handleRemove = (userId) => {
    setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
  };

  const handleAddUser = () => {
    setUsers((prevUsers) => [...prevUsers, newUser]);
    setNewUser({
      id: newUser.id + 1,
      type: 'user',
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      role: 'empty',
      password: '',
      online: false,
      isEditing: true,
      iconColor: 'black',
    });
  };

  const handleResetPassword = (userId) => {
    console.log(`Reset password for user with ID ${userId}`);
  };

  const handleRoleChange = (selectedRole, userId) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, role: selectedRole } : user
      )
    );
  };

  const handleCloseRolesModal = () => {
    setShowRolesModal(false);
  };

  const handleAddRole = () => {
    setRoles((prevRoles) => [...prevRoles, newRole]);
    setNewRole({
      id: newRole.id + 1,
      name: '',
      permissions: [],
      description: '',
      isEditing: true,
    });
  };

  const handleEditRole = (roleId) => {
    setRoles((prevRoles) =>
      prevRoles.map((role) =>
        role.id === roleId ? { ...role, isEditing: !role.isEditing } : role
      )
    );
  };

  const handleSaveRoleChanges = (roleId) => {
    setRoles((prevRoles) =>
      prevRoles.map((role) =>
        role.id === roleId ? { ...role, isEditing: false } : role
      )
    );
  };

  const handleRemoveRole = (roleId) => {
    setRoles((prevRoles) => prevRoles.filter((role) => role.id !== roleId));
  };

  return (
    <Container className="text-center mb-4 text-light bg-dark p-3">
      <Button variant="primary" className="mb-3" onClick={handleAddUser}>
        <FaUserPlus /> Add User
      </Button>{' '}
      <Button variant="primary" className="mb-3" onClick={() => setShowRolesModal(true)}>
        Roles
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>User Name</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Password</th>
            <th>Role</th>
            <th>Online</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.type === 'admin' ? <FaUserCog color={user.iconColor} /> : <FaUser />}</td>
              <td>{user.isEditing ? <Form.Control type="text" value={user.userName} /> : user.userName}</td>
              <td>{user.isEditing ? <Form.Control type="text" value={user.firstName} /> : user.firstName}</td>
              <td>{user.isEditing ? <Form.Control type="text" value={user.lastName} /> : user.lastName}</td>
              <td>{user.isEditing ? <Form.Control type="text" value={user.email} /> : user.email}</td>
              <td>{user.isEditing ? <Form.Control type="text" value={user.password} /> : user.password}</td>
              <td>
                {user.isEditing ? (
                  <Dropdown onSelect={(selectedRole) => handleRoleChange(selectedRole, user.id)}>
                    <Dropdown.Toggle variant="info" id="dropdown-basic">
                      {user.role}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {roles.map((role) => (
                        <Dropdown.Item key={role.id} eventKey={role.name}>
                          {role.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  user.role
                )}
              </td>
              <td>{user.online ? <FaCheck className="text-success" /> : <FaTimes className="text-danger" />}</td>
              <td>
                {user.isEditing ? (
                  <div className="d-flex align-items-center">
                    <Button
                      variant="primary"
                      className="ms-2 icon-admin"
                      onClick={() => handleSaveChanges(user.id)}
                    >
                      <FaCheck />
                    </Button>
                    <Button
                      variant="danger"
                      className="ms-2 icon-admin"
                      onClick={() => handleRemove(user.id)}
                    >
                      <FaTrash />
                    </Button>
                    <Button
                      variant="warning"
                      className="ms-2 icon-admin"
                      onClick={() => handleResetPassword(user.id)}
                    >
                      <FaKey />
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="primary"
                    onClick={() => handleEdit(user.id)}
                  >
                    <FaEdit />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showRolesModal} onHide={handleCloseRolesModal} size="xl" className="text-light">
        <Modal.Header closeButton>
          <Modal.Title>Roles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Role</th>
                <th>Permissions</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {roles.map((role) => (
                <tr key={role.id}>
                  <td>{role.id}</td>
                  <td>{role.isEditing ? <Form.Control type="text" value={role.name} /> : role.name}</td>
                  <td>
                    {role.isEditing ? (
                      <Form.Control
                        type="text"
                        value={role.permissions.join(', ')}
                      />
                    ) : (
                      role.permissions.join(', ')
                    )}
                  </td>
                  <td>{role.isEditing ? <Form.Control type="text" value={role.description} /> : role.description}</td>
                  <td>
                    {role.isEditing ? (
                      <>
                        <Button
                          variant="primary"
                          onClick={() => handleSaveRoleChanges(role.id)}
                        >
                          <FaCheck />
                        </Button>{' '}
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveRole(role.id)}
                        >
                          <FaTrash />
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={() => handleEditRole(role.id)}
                      >
                        <FaEdit />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseRolesModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddRole}>
            <FaPlus /> Add Role
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UsersPage;
