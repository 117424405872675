import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

const ProfileAccount = () => {
  const [loginEmail, setLoginEmail] = useState('example@mail.ru');
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState('');
  const [timezone, setTimezone] = useState('');
  const [language, setLanguage] = useState('ru');

  const handleLoginEmailChange = (e) => {
    setLoginEmail(e.target.value);
    setShowConfirmEmail(true);
  };

  const handleTimezoneChange = (e) => {
    setTimezone(e.target.value);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const saveLoginEmail = () => {
    console.log('Email для входа:', loginEmail);
  };

  const saveTimezone = () => {
    console.log('Часовой пояс:', timezone);
  };

  const saveLanguage = () => {
    console.log('Язык интерфейса:', language);
  };

  return (
    <>
      <Row className='bg-dark'>
        <div className='text-light'>
          <h2>Аккаунт</h2>
          <p className='opacity-75'>Управляйте настройками вашего аккаунта здесь.</p>
        </div>
      </Row>
      <Row className="profile-account-container bg-dark text-light">
        <Col sm={12}>
          <h3>Login Email</h3>
          <Form>
            <Form.Group controlId="formLoginEmail">
              <Form.Control
                type="email"
                placeholder="example@mail.ru"
                value={loginEmail}
                onChange={handleLoginEmailChange}
              />
            </Form.Group>
            {showConfirmEmail && (
              <Form.Group controlId="formConfirmEmail">
                <Form.Control
                  type="email"
                  placeholder="Подтвердите ваш Email"
                  value={confirmEmail}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                />
              </Form.Group>
            )}
            <Button variant="primary" onClick={saveLoginEmail}>Сохранить</Button>
          </Form>
        </Col>
        <Col sm={12} className="mt-4">
          <h3>Timezone</h3>
          <Form>
            <Form.Group controlId="formTimezone">
              <Form.Control
                as="select"
                value={timezone}
                onChange={handleTimezoneChange}
              >
                <option value="">Выберите часовой пояс</option>
                <option value="GMT-12">GMT-12</option>
                <option value="GMT-11">GMT-11</option>
                <option value="GMT-10">GMT-10</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" onClick={saveTimezone}>Сохранить</Button>
          </Form>
        </Col>
        <Col sm={12} className="mt-4">
          <h3>Language</h3>
          <Form>
            <Form.Group controlId="formLanguage">
              <Form.Control
                as="select"
                value={language}
                onChange={handleLanguageChange}
              >
                <option value="ru">Русский</option>
                <option value="en">English</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" onClick={saveLanguage}>Сохранить</Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ProfileAccount;
