import React, { useState } from 'react';
import { Row, Col, Form, Alert } from 'react-bootstrap';

const ProfileTwoFactorAuth = () => {
  const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const toggleTwoFactorAuth = () => {
    setTwoFactorAuthEnabled(!twoFactorAuthEnabled);
    setShowSuccessAlert(true);
  };

  return (
    <>
      <Row className='bg-dark text-dark'>
        <div className='text-light'>
          <h2>Двухфакторная аутентификация</h2>
          <p className='opacity-75'>Двухфакторная аутентификация помогает защитить ваш аккаунт GraviLink от взлома и потери данных.</p>
        </div>
      </Row>
      <Row className="profile-two-factor-auth-container bg-dark text-light">
        <Col sm={12}>
          <h3>Используйте специальное приложение для двухфакторной аутентификации</h3>
          <p>Для включения двухфакторной аутентификации выполните следующие шаги:</p>
          <ol>
            <li>Скачайте приложение "GraviLink TFA" из App Store или Google Play</li>
            <li>Откройте приложение</li>
            <li>Нажмите кнопку "Запросить код"</li>
          </ol>
          <Form>
            <Form.Group controlId="formTwoFactorAuth">
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Включить двухфакторную аутентификацию"
                checked={twoFactorAuthEnabled}
                onChange={toggleTwoFactorAuth}
              />
            </Form.Group>
          </Form>
          {showSuccessAlert && (
            <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
              Двухфакторная аутентификация {twoFactorAuthEnabled ? 'включена' : 'отключена'}.
            </Alert>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProfileTwoFactorAuth;
