import pricingList from '../../helpers/pricingList';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";


const Pricing = () => {
  return (
    <Container className="mt-0 text-light bg-dark">
      <Row className="pt-5 m-5 mx-2 text-center border-bottom">
        <Col>
          <h1 className="">Ценообразование</h1>
          <p className="opacity-75">Выберите тариф</p>
        </Col>
      </Row>

      <Row className="row-cols-1 row-cols-md-3 ">
        {pricingList.map((plan) => (
          <Col>
            <Card className="mb-4 rounded-3 shadow-sm text-light bg-dark">
              <Card.Header className="py-3">
                <h4 className="my-0 fw-normal text-center">{plan.name}</h4>
              </Card.Header>
              <Card.Body>
                <Card.Title className="pricing-card-title text-gold">{plan.price} / month</Card.Title>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>{plan.description}</li>
                  <li>{plan.value1}</li>
                  <li>{plan.value2}</li>
                </ul>
                <NavLink to={"/billing"} className="btn btn-primary mt-1  d-flex justify-content-center">Выбрать</NavLink>
                {/* <Button variant="outline-primary" size="lg" className="w-100">Выбрать</Button> */}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

    </Container>
  );
};

export default Pricing;
