import axios from 'axios'

export const API_URL = process.env.REACT_APP_DATASET_SERVICE;
//export const API_URL = 'http://localhost:1201/'

export const $datasetServiceApi = axios.create({
    baseURL: API_URL,
    withCredentials: true
})

export default $datasetServiceApi
