import { useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./styles/site.css";
import './styles/navbar-fixed.css';
import Header from './componets/header/header';
import Login from "./componets/header/Login";
//Profile
import Profile from "./componets/pages/Profile";

import Registration from "./componets/header/Registration";
import Footer from "./componets/footer/Footer";
import Home from "./componets/pages/Home";
import Projects from "./componets/pages/Projects";
import ProjectCreate from "./componets/pages/ProjectCreate";
import ProjectEdit from "./componets/pages/ProjectEdit";
import ProjectView from "./componets/pages/ProjectView";
import ProjectsTrash from "./componets/pages/ProjectsTrash";

import GProjects from "./componets/pages/GProjects";

import Contacts from "./componets/pages/Contacts";
import ScrollToTop from "./utils/scrollToTop";
import Pricing from "./componets/pages/Pricing";
import PricingEdit from "./componets/pages/PricingEdit";
import Billing from "./componets/pages/Billing";
import UsersPage from "./componets/pages/UsersPage";

import Notifications from "./componets/pages/Notifications";
import UserComponent from "./services/UserComponent"
// import Covid from "./componets/pages/MedProjects/Covid";

import { AuthContext } from "./services/userService";
import AllLinksPage from "./componets/pages/AllLinksPage";


function App() {
    const { isUserLogged } = useContext(AuthContext)
    const { tokenTest } = useContext(AuthContext)
    const { isDevelop } = useContext(AuthContext)

    return (
        <div className="App" >
            <Router>
                <ScrollToTop />
                {/* <div> tokenTest : {tokenTest}</div>
                {isUserLogged && (<div> isUserLogged:{isUserLogged} tokenTest : {tokenTest}</div>)} */}
                <Header />
                <Routes>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/profile" element={<Profile />}></Route>
                    <Route path="/registration" element={<Registration />}></Route>
                    {isDevelop && (
                        <>
                        <Route path="/userComponent" element={<UserComponent />} />
                        <Route path="/links" element={<AllLinksPage />} />
                        <Route path="/usersPage" element={<UsersPage />}></Route>
                        </>
                    )}
                    <Route path="/" element={<Home />}></Route>
                    {!isUserLogged ? (
                        <>
                        <Route path="/" element={<Home />}></Route>
                        </>
                    ) : (
                        <>
                            <Route path="/profile" element={<Profile />}></Route>
                            <Route path="/notifications" element={<Notifications />}></Route>

                            <Route path="/projects" element={<Projects />}></Route>
                            <Route path="/projectCreate" element={<ProjectCreate />}></Route>
                            <Route path="/projectEdit" element={<ProjectEdit />}></Route>
                            <Route path="/projectView/:id" element={<ProjectView />}></Route>
                            <Route path="/projectTrash" element={<ProjectsTrash />}></Route>
                            <Route path="/gprojects" element={<GProjects />}></Route>


                            {/* <Route path="/covid" element={<Covid />}></Route> */}

                            <Route path="/contacts" element={<Contacts />}></Route>

                            <Route path="/pricing" element={<Pricing />}></Route>
                            <Route path="/pricingEdit" element={<PricingEdit />}></Route>
                            <Route path="/billing" element={<Billing />}></Route>
                        </>
                    )}

                </Routes>
                <div className="b-example-divider"></div>
                <Footer />
            </Router>
        </div>

    );
}

export default App;
