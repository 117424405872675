import React from 'react';
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import RegisterComponent from './RegisterComponent';
import LoginComponent from './LoginComponent';
import TokenComponent from './TokenComponent';
import UserRead from './UserRead';
import UserDelete from './UserDelete';
import UserUpdate from './UserUpdate';
import ProjectCreate from './ProjectCreate';
import DatasetDomainsCreate from './DatasetDomainsCreate';
import DatasetTaskTypesCreate from './DatasetTaskTypesCreate';
import ProjectRead from './ProjectRead';
import ProjectUpdate from './ProjectUpdate';
import ProjectPatch from './ProjectPatch';
import ProjectDelete from './ProjectDelete';
import ModelRead from './ModelRead';
import ModelTrain from './ModelTrain';
import ModelCreate from './ModelCreate';
import ModelUpdate from './ModelUpdate';
import ModelDelete from './ModelDelete';
import ModelApply from './ModelApply';

const UserComponent = () => {
    return (
        <div>
            <Container className="text-center mb-4 text-light bg-dark p-3">
                <Row className="justify-content-center">
                    <Col md={6}>
                        <h1>USER SERVICE</h1>
                        <RegisterComponent />
                        <LoginComponent />
                        <TokenComponent />
                        <UserRead />
                        <UserDelete />
                        <UserUpdate />
                    </Col>
                    <Col md={6}>
                        <h1>PROJECT SERVICE</h1>
                        <ProjectCreate />
                        <ProjectRead />
                        <ProjectUpdate />
                        <ProjectPatch />
                        <ProjectDelete />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <h1>Dataset SERVICE</h1>
                        <DatasetDomainsCreate />
                        <DatasetTaskTypesCreate />

                    </Col>
                    <Col md={6}>
                        <h1>Model SERVICE</h1>
                        <ModelCreate />
                        <ModelRead />
                        <ModelTrain />
                        <ModelUpdate />
                        <ModelDelete />
                        <ModelApply />
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default UserComponent;
