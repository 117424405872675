import { useState } from 'react';
import { Button, Form, ListGroup } from 'react-bootstrap';
import './../../styles/site.css';

const ProjectEdit = () => {
  const handleUpdateProject = () => {
    // alert('Project Updated!');
  };
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <main className="container text-light bg-dark p-3">
      <div className="container text-left">
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex p-2 align-items-center">
              <h2>Редактировать проект</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <Form>
              {/* Project Details */}
              <div className="mb-3">
                <Form.Label>
                  Название проекта:
                </Form.Label>
                <Form.Control type="text" placeholder="Обновленное название проекта" />
              </div>

              <div className="mb-3">
                <Form.Label>
                  Описание проекта:
                </Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Обновленное описание проекта" />
              </div>

              <div className="mb-3">
                <Form.Label>
                  Выберите предопределенное поведение:
                </Form.Label>
                <Form.Select>
                  <option value="по умолчанию">по умолчанию</option>
                  <option value="model1">Model 1</option>
                  <option value="model2">Model 2</option>
                </Form.Select>
              </div>

              <div className="mb-3">
                <Form.Label>
                  Выберите папку проекта:
                </Form.Label>
                <Form.Select>
                  <option value="folder1">Все проекты</option>
                  <option value="folder2">Медицина</option>
                </Form.Select>
              </div>

              {/* Model List */}
              <div className="mb-3">
                <Form.Label>
                  История обновления модели:
                </Form.Label>
                <ListGroup>
                  <ListGroup.Item className="bg-dark text-light">Модель от 01.01.22</ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light">Модель от 01.02.23</ListGroup.Item>
                </ListGroup>
              </div>
              <div className="row mt-3 text-center">
                <Button variant="primary" onClick={handleUpdateProject}>Запустить процесс обучения модели</Button>
              </div>

              <Form.Group controlId="globalProjectsCheckbox" className="mb-3 mt-3">
                <Form.Check
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  label="Опубликовать в глобальных проектах. Данный проект будет доступен всем зарегистрированным пользователям. Важно отметить, что эти пользователи не будут иметь возможности вносить изменения в проект или получать доступ к подробной информации о нем и о связанных с ним данных"
                />
              </Form.Group>
              { }
              <div className="row mt-3 text-center">
                <div className="col">
                  <Button variant="primary" onClick={handleUpdateProject}>Обновить</Button>
                  <span className="mx-2"></span>
                  <Button variant="secondary">Отменить</Button>
                  <span className="mx-2"></span>
                  <Button variant="danger">Удалить</Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProjectEdit;
