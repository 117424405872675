import axios from 'axios'

export const API_URL = process.env.REACT_APP_PROJECT_SERVICE;
//export const API_URL = 'http://localhost:1203/'

export const $projectServiceApi = axios.create({
    baseURL: API_URL,
    withCredentials: true
})

export default $projectServiceApi
