import { useState, useContext } from 'react';
import { AuthContext } from './userService';


const TokenComponent = () => {
    const { getToken } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [error, setError] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [status, setStatus] = useState('');
    const [accessToken, setAccessToken] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };

    const handleGetToken = async () => {
        if (!email || !code) {
            setError('Пожалуйста, введите email и код');
            return;
        }

        try {
            const tokenData = { email, code };
            const response = await getToken(tokenData);
            setResponseMessage(response.data);
            setStatus(response.status);
            setError(null);
        } catch (error) {
            setError('Произошла ошибка при получении токена: ' + error);
        }
    };

    return (
        <div>
            <h2>Получение токена</h2>
            <input type="text" value={email} onChange={handleEmailChange} placeholder="Введите email" />
            <input type="text" value={code} onChange={handleCodeChange} placeholder="Введите код" />
            <button onClick={handleGetToken}>Получить токен</button>
            {error && <div>{error}</div>}
            {status && <div>Статус: {status}  responseMessage: {responseMessage}</div>}
            {console.log("accessToken:" + accessToken)}
        </div>
    );
};

export default TokenComponent;
