import GProject from "../gproject/GProject";
import { projects } from "../../helpers/projectList";

const GProjects = () => {
    return (
        <main className="container text-center text-light">
            <div className="row">
                <div className="col-md-auto">
                    <div className="d-flex p-2 align-items-center">
                        <h2>Глобальные проекты</h2>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="py-1">
                        <div className="container">
                            <div className="row row-cols-1 row-cols-md-3 g-2">
                                {projects
                                    .filter(project => project.isTrash)
                                    .map((project, index) => (
                                        <div key={project.id}>
                                            <GProject
                                                title={project.title}
                                                img={project.img}
                                                colorStatus={project.colorStatus}
                                                dataPoz={project.dataPoz}
                                                dataNeg={project.dataNeg}
                                                index={index}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col">
                    <div className="py-1">
                        <div className="container">
                            <div className="row row-cols-1 row-cols-md-3 g-2">
                                {projects
                                    .filter(project => project.isTrash)
                                    .map((project, index) => (
                                        <div key={project.id}>
                                            <GProject
                                                title={project.title}
                                                img={project.img}
                                                colorStatus={project.colorStatus}
                                                dataPoz={project.dataPoz}
                                                dataNeg={project.dataNeg}
                                                index={index}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    );
};

export default GProjects;
