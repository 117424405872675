const leftMenuList = [
    {
        id: 1,
        projectName: 'Все проекты',
        projectCount: 0, // общее количество проектов
        colorStatus: 'bg-primary',
        URL: ''
    },
    {
        id: 2,
        projectName: 'Медицина',
        projectCount: 120,
        colorStatus: 'bg-warning',
        URL: ''
    },
    {
        id: 3,
        projectName: 'Финансы',
        projectCount: 81,
        colorStatus: 'bg-success',
        URL: ''
    },
    {
        id: 4,
        projectName: 'Технологии',
        projectCount: 60,
        colorStatus: 'bg-danger',
        URL: ''
    },
    {
        id: 5,
        projectName: 'Образование',
        projectCount: 94,
        colorStatus: 'bg-info',
        URL: ''
    },
    {
        id: 6,
        projectName: 'Искусство',
        projectCount: 47,
        colorStatus: 'bg-secondary',
        URL: ''
    },
    {
        id: 7,
        projectName: 'Социальная сфера',
        projectCount: 75,
        colorStatus: '',
        URL: ''
    }
]

const totalProjectCount = leftMenuList.slice(1).reduce((sum, project) => sum + project.projectCount, 0);

leftMenuList[0].projectCount = totalProjectCount;
export { leftMenuList }