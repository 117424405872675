import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

const ProfileResume = () => {
  return (
    <>
      <Row className='bg-dark text-light'>
        <div className='text-light'>
          <h2>Резюме</h2>
          <p>Укажите информацию о вашем опыте работы и образовании</p>
        </div>
      </Row>
      <Row className="profile-resume-container bg-dark text-light">
        <Col sm={12} className="mb-4">
          <h3>Опыт работы</h3>
          <Form>
            <Form.Group controlId="formDescription" className="mb-4">
              <Form.Label>Описание работы</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Введите описание вашей работы" />
            </Form.Group>
            <Form.Group controlId="formExperience" className="mb-4">
              <Form.Label>Название компании</Form.Label>
              <Form.Control type="text" placeholder="Введите название компании" />
            </Form.Group>
            <Form.Group controlId="formPosition" className="mb-4">
              <Form.Label>Должность</Form.Label>
              <Form.Control type="text" placeholder="Введите вашу должность" />
            </Form.Group>
            <Form.Group controlId="formResume" className="mb-4">
              <Form.Label>Загрузить резюме (PDF)</Form.Label>
              <Form.Control type="file" accept=".pdf" />
            </Form.Group>
            <Button variant="primary">Сохранить</Button>
          </Form>
        </Col>
        <Col sm={12}>
          <hr />
        </Col>
        <Col sm={12} className="mb-4">
          <h3>Образование</h3>
          <Form>
            <Form.Group controlId="formName" className="mb-4">
              <Form.Label>Образование</Form.Label>
              <Form.Control type="text" placeholder="Введите ваше образование" />
            </Form.Group>
            <Form.Group controlId="formSpecialization" className="mb-4">
              <Form.Label>Специализация</Form.Label>
              <Form.Control type="text" placeholder="Введите вашу специализацию" />
            </Form.Group>
            <Form.Group controlId="formAdditionalInfo" className="mb-4">
              <Form.Label>Дополнительная информация</Form.Label>
              <Form.Control type="text" placeholder="Введите дополнительную информацию" />
            </Form.Group>
            <Button variant="primary">Сохранить</Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ProfileResume;
