import React, { useState } from 'react';
import { Container, Row, Col, Image, Button, Form, ListGroup } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { projects } from '../../helpers/projectList';

const ProjectView = () => {
  const { id } = useParams();
  const project = projects[id];
  const [emailInputVisible, setEmailInputVisible] = useState(false);
  const [email, setEmail] = useState('');
  const handleFileUpload = (event) => {
    const fileInput = event.target;
    const selectedFile = fileInput.files[0];
    console.log('Selected File:', selectedFile);
  };

  const handleSendEmailClick = () => {
    setEmailInputVisible(true);
  };

  const handleCancelSendEmailClick = () => {
    setEmailInputVisible(false);
  };

  const handleSendEmail = () => {
    console.log('Email Sent:', email);
  };

  const getRandomPercentage = () => Math.floor(Math.random() * (100 - 64) + 64);

  return (
    <Container className="text-center mb-4 text-light bg-dark p-3">
      <Row>
        <Col>
          <h2>{project.title}</h2>
        </Col>
      </Row>

      <Row className="text-center">
        <Col>
          <Image src={project.imgBig} fluid />
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="mt-3">{project.description}</p>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <p>
            На основе {project.dataPoz} позитивных и {project.dataNeg} негативных изображений.
          </p>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <ListGroup>
            {project.currentModels.map((model, index) => (
              <ListGroup.Item key={index} className={index === project.currentModels.length - 1 ? 'bg-info' : 'bg-dark text-light'}>
                {model}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>

      <Row className="mt-3 justify-content-center align-items-center">
        {[1, 2, 3, 4, 5].map((index) => (
          <React.Fragment key={index}>
            <Col md={6} className="text-center">
              <Image src={`https://via.placeholder.com/600x400?text=Image+${index}`} fluid className="mb-3" />
              <ListGroup.Item>{`${getRandomPercentage()}% Positive`}</ListGroup.Item>
            </Col>
            <Col md={6} className="text-center">
              <Image src={`https://via.placeholder.com/600x400?text=Image+${index}`} fluid className="mb-3" />
              <ListGroup.Item>{`${getRandomPercentage()}% Positive`}</ListGroup.Item>
            </Col>
            <Col md={12}>
              <hr className="my-4" />
            </Col>
          </React.Fragment>
        ))}
      </Row>

      <Row className="mt-3">
        <Col>
          <Form.Control type="file" onChange={handleFileUpload} />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Button className="me-1">Сохранить как PDF</Button>
          <Button onClick={handleSendEmailClick} className="me-1">
            Отправить на почту
          </Button>

          <NavLink to="/projects"><Button variant="secondary">Вернуться</Button></NavLink>
        </Col>
      </Row>

      {emailInputVisible && (
        <Row className="mt-3">
          <Col>
            <Form.Control
              type="email"
              placeholder="Введите email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Col>
          <Col>
            <Button className="ms-1" onClick={handleSendEmail}>
              Отправить
            </Button>
            <Button variant="secondary" onClick={handleCancelSendEmailClick} className="ms-1">
              Отмена
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ProjectView;
