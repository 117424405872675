
const notificationsList = [
    {
        id: 1,
        name: 'Системное уведомление',
        date: '01.01.2024',
        text: 'Ваша модель успешно обучена.',
        colorClass: 'bg-info',
        colorText: 'text-dark'
    },
    {
        id: 2,
        name: 'Критическое уведомление',
        date: '02.01.2024',
        text: 'Подписка на тарифный план "Pro" закончится 01.03.2024.',
        colorClass: 'bg-danger',
        colorText: 'text-dark'
    },
    {
        id: 3,
        name: 'Уведомление о задаче',
        date: '03.03.2024',
        text: 'Задача выполнена успешно.',
        colorClass: 'bg-success',
        colorText: 'text-light'
    },
    {
        id: 4,
        name: 'Информационное уведомление',
        date: '04.04.2024',
        text: 'Новая версия программы доступна для загрузки.',
        colorClass: 'bg-primary',
        colorText: 'text-light'
    },
    {
        id: 5,
        name: 'Уведомление о состоянии системы',
        date: '05.05.2024',
        text: 'Система перешла в режим обслуживания.',
        colorClass: 'bg-warning',
        colorText: 'text-dark'
    }
];

export default notificationsList;
