

const pricingList = [
  {
    id: 1,
    name: '< Базовый >',
    price: '$ 19.99',
    description: 'Для индивидуального использования',
    value1: '2 Gb для датасета',
    value2: 'Доступ к глобальным проектам'
  },
  {
    id: 2,
    name: '< Стандартный >',
    price: '$ 39.99',
    description: 'Для малой команды',
    value1: '5 Gb для датасета',
    value2: 'Доступ и создание глобальных проектов'
  },
  {
    id: 3,
    name: '< Расширенный >',
    price: '$ 159.99',
    description: 'Без ограничений',
    value1: '10 Gb для датасета',
    value2: 'Доступ и создание глобальных проектов'
  },
];

export default pricingList;
