import React from 'react';
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import notificationsList from '../../helpers/notificationsList';

const Notifications = () => {
  return (
    <Container className="text-center mb-4 text-light bg-dark p-3">
      <Row>
        <div className="col-lg-12">
          <div className="d-flex p-2 align-items-center">
            <h2>Уведомления</h2>
          </div>
        </div>
        <Col md={12}>
          {notificationsList.map((notification, index) => (
            <div className={`m-3 ${notification.colorText}`}>
            <div key={index} className={notification.colorClass}>
              {notification.name} от {notification.date}: {notification.text}
            </div>
            </div>
          ))}
        </Col>
      </Row>
      {/* <Button variant="primary" type="submit">
        Удалить все уведомления
      </Button> */}
    </Container>
  );
};

export default Notifications;
