import axios from 'axios'

export const API_URL = process.env.REACT_APP_MODEL_SERVICE;
//export const API_URL = 'http://localhost:1202/'

export const $modelServiceApi = axios.create({
    baseURL: API_URL,
    withCredentials: true
})

export default $modelServiceApi
