import { useState, useContext } from 'react';
import { AuthContext } from './userService';
import { Container, Row, Col, Image, Form } from 'react-bootstrap';
import config from './config';

const ModelApply = () => {
    const { handleModelApply } = useContext(AuthContext);
    const [name, setName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [error, setError] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [status, setStatus] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const formData = new FormData();
    formData.append('file', selectedFile);

    const handleReadUser = async () => {
        if (!name) {
            setError('Пожалуйста, введите id код и email');
            return;
        }

        try {
            const response = await handleModelApply(name, selectedFile);
            setResponseMessage(response);
            setStatus(response);
            setError(null);
        } catch (error) {
            if (error.response) {
                const { data, status, headers } = error.response;
                console.error(data);
            } else if (error.request) {
                console.error(error.request);
            } else {
                console.error(error.message);
            }
            console.error(error.config);
        }
    };

    return (
        <div>
            <h2>Apply модель</h2>
            <input type="text" value={name} onChange={handleNameChange} placeholder="Введите id проекта" />
            <button onClick={handleReadUser}>Ок</button>
            <div>Статус: {status}</div>
            {error && <div>{error}</div>}
            <Form.Control type="file" accept=".png, .jpg" id="upload-image" onChange={(e) => {
                const files = e.target.files;
                if (files && files.length > 0) {
                    setSelectedFile(files[0]);
                    console.log("Selected File:", files[0]);
                }
            }} />
        </div>
    );
};

export default ModelApply;
