import { Container, Row, Col, Form, Image } from 'react-bootstrap';

const Contacts = () => {
  return (
    <>
    <Container className="mt-0 text-light bg-dark">
      <Row className="pt-5 m-5 mx-2 text-center border-bottom">
      <Col>
        <h1>Свяжитесь с нами</h1>
        <p className="opacity-75">Желаете сотрудничать с нами? Мы рады рассмотреть ваше предложение!</p>
        </Col>
      </Row>

      <Row className="pt-0 m-0 mx-0">
        <Col md={3} className="m-0 mx-5">
          <Row className="mt-0">
            <h4>Адрес:</h4>
            <p className="opacity-75">город Санкт-Петербург<br /> пр-кт Малый В.О., д. 62 к. 1 литера А<br />пом/офис 178н/2.5</p>
          </Row>
          <Row className="mt-2">
            <h4>Электронная почта:</h4>
            <p className="opacity-75">info@gravilink.com</p>
          </Row>
          <Row className="mt-2">
            <h4>Телефон:</h4>
            <p className="opacity-75">+7 952 375 3105</p>
          </Row>
          <Row className="mt-2 mb-4">
            <h4>Корпоротаивный сайт:</h4>
            <a className="opacity-75 fs-4" href="https://gravilink.com/" target="_blank">https://gravilink.com/</a>
          </Row>
        </Col>

        <Col md={8} className="m-0 mx-0">
          <form className="mb-0">

            <h4>Отправте нам сообщение</h4>
            <div className="mb-0">
              <Form.Label className="text-body-secondary mt-1 mb-4">
                Оставте контактные данные и мы свяжемся с вами
              </Form.Label>
              <Row className="g-3">
                <Col sm={6}>
                  <Form.Group controlId="firstName">
                    <Form.Label>Ваше имя</Form.Label>
                    <Form.Control type="text" placeholder="Иван" required />
                    <Form.Control.Feedback type="invalid">Ваше имя</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group controlId="email">
                    <Form.Label>Электронная почта</Form.Label>
                    <Form.Control type="text" placeholder="example@mail.com" required />
                    <Form.Control.Feedback type="invalid">Valid last name is required.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Control className="py-1" as="textarea" rows={3} placeholder="Текстовое сообщение..." />
            </div>
            <button className="mt-3 btn btn-primary" type="button">Отправить</button>
          </form>

        </Col>
      </Row>
    </Container>


</>
  );
};

export default Contacts;
