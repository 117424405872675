import React, { useState } from 'react';
import { Row, Col, FormControl, Button, Dropdown, Form } from 'react-bootstrap';
import { FaTrash, FaGlobe, FaYoutube, FaLinkedin } from 'react-icons/fa';

const ProfileSocial = () => {
  const socialMediaOptions = [
    { name: 'Website', value: 'Website', placeholder: 'Введите URL вашего сайта', prefix: 'https://', icon: FaGlobe },
    { name: 'YouTube', value: 'YouTube', placeholder: 'Введите наименование вашего канала на YouTube', prefix: 'https://www.youtube.com/channel/', icon: FaYoutube },
    { name: 'LinkedIn', value: 'LinkedIn', placeholder: 'Введите ваш профиль на LinkedIn', prefix: 'https://www.linkedin.com/in/', icon: FaLinkedin }
  ];

  const [selectedSocialMedia, setSelectedSocialMedia] = useState(socialMediaOptions[0].name);
  const [inputPlaceholder, setInputPlaceholder] = useState(socialMediaOptions[0].placeholder);
  const [socialItems, setSocialItems] = useState([]);
  const handleSocialMedia = (socialMedia) => {
    const selectedOption = socialMediaOptions.find(option => option.value === socialMedia);
    setSelectedSocialMedia(selectedOption.name);
    setInputPlaceholder(selectedOption.placeholder);
  };

  const handleAdd = () => {
    const inputValue = document.getElementById('socialInput').value;
    if (inputValue.trim() !== '') {
      const newItem = {
        id: Date.now(),
        socialMedia: selectedSocialMedia,
        text: socialMediaOptions.find(option => option.name === selectedSocialMedia).prefix + inputValue
      };
      setSocialItems(prevItems => [...prevItems, newItem]);
    }
  };

  const handleDelete = (id) => {
    const updatedItems = socialItems.filter(item => item.id !== id);
    setSocialItems(updatedItems);
  };

  return (
    <>
      <Row className='bg-dark text-light'>
        <div className='text-light'>
          <h2>Соцсети</h2>
          <p>Укажите информацию о контактах и социальных сетях. Они будут доступны пользователям GraviLink</p>
        </div>
      </Row>
      <Row className='bg-dark text-light' id="socialContainer">
        {socialItems.map(item => (
          <Col key={item.id} sm={12}>
            <p className="d-flex justify-content-between align-items-center">
              {socialMediaOptions.find(option => option.name === item.socialMedia).icon()}
              <span className="mr-auto">{item.text}</span>
              <FaTrash className="delete-icon" onClick={() => handleDelete(item.id)} />
            </p>
          </Col>
        ))}
      </Row>
      <Row className="social-media-dropdown-container bg-dark text-light">
        <Col sm={2}>
          <Form>
            <Form.Group controlId="socialMedia">
              <Dropdown onSelect={handleSocialMedia}>
                <Dropdown.Toggle variant="" id="dropdown-country" className="text-light">
                  {selectedSocialMedia ? selectedSocialMedia : 'Выберите соцсеть'}
                </Dropdown.Toggle>
                <Dropdown.Menu className="bg-dark text-light">
                  {socialMediaOptions.map((option, index) => (
                    <Dropdown.Item key={index} eventKey={option.value} className="text-light">
                      {option.icon()} {option.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </Form>
        </Col>
        <Col sm={8}>
          <FormControl
            id="socialInput"
            type="text"
            placeholder={inputPlaceholder}
            className="mr-sm-2"
          />
        </Col>
        <Col sm={2}>
          <Button variant="primary" onClick={handleAdd}>Добавить</Button> {/*кнопка запуска*/}
        </Col>
      </Row>
    </>
  );
};

export default ProfileSocial;
