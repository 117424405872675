import { useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../services/userService';

const Login = () => {

    const { requestCode, getToken } = useContext(AuthContext);
    const [result, setResult] = useState("");
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('')
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };

    const handleRequestCode = async () => {
        try {
            await requestCode(email);
            setShowCodeInput(true);
            setResult("");
        } catch (error) {
            setResult("Ошибка при запросе кода. Пожалуйста, попробуйте еще раз.");
        }
    };

    const handleLogin = async () => {
        if (!email || !code) {
            setError('Пожалуйста, введите email и код');
            return;
        }
        try {
            const tokenData = { email, code };
            await getToken(tokenData);
            setResult("Успешный вход!");
        } catch (error) {
            setResult("Ошибка при входе. Пожалуйста, проверьте ваш email и код.");
        }
    };

    return (
        <Container className="text-center mb-4 text-light bg-dark p-3">
            <Form>
                <h4 className="text-center mb-4">Авторизация</h4>
                <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={email} onChange={handleEmailChange} placeholder="Введите ваш email" required />
                </Form.Group>
                <Button onClick={handleRequestCode} variant="primary" >
                    Получить код
                </Button>
                {showCodeInput && (
                    <Form.Group controlId="formCode">
                        <Form.Label>Код</Form.Label>
                        <Form.Control type="text" value={code} onChange={handleCodeChange} placeholder="Введите полученный код" required />
                    </Form.Group>
                )}
                {showCodeInput && (
                    <Button className='' onClick={handleLogin} variant="primary" >
                        Войти
                    </Button>
                )}
            </Form>
            {result && <Alert variant={result.includes("Ошибка") ? "danger" : "success"}>{result}</Alert>}
            <Row className="mt-3 text-center">
                <Col>
                    <p><NavLink to="/registration">Зарегистрироваться</NavLink></p>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
