import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Form } from 'react-bootstrap';
import project01BigCovid from "./../../../img/projects/1200x400_COVID.jpg";
import processingGif from "./../../../img/processing.gif"; // путь к вашему gif изображению
import './style.css';

class ProjectInfo {
  constructor() {
    this.id = "1";
    this.name = "covid-ct.zip";
    this.status = "ready";
  }
}

const Covid = () => {
  const [projectsData, setProjectsData] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [fileQueue, setFileQueue] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);

  const fetchProjects = async () => {
    try {
      const response = await fetch("https://aml.gravilink.com/get_all_projects");
      const data = await response.json();
      setProjectsData(data);
      if (data.length > 0) {
        setSelectedProject(data[0].id);
        console.log("Selected File:", data[0].id);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
      if (error instanceof Error) {
        alert('Error fetching projects: ' + error.message);
      } else {
        alert('An unknown error occurred.');
      }
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    const processQueue = async () => {
      if (fileQueue.length === 0 || isProcessing) {
        return;
      }

      setIsProcessing(true);
      const fileObj = fileQueue[0];
      const selectedFile = fileObj.file;
      const selectedProjectInstance = new ProjectInfo();
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await fetch(`https://aml.gravilink.com/infer_image/${selectedProjectInstance.id}`, {
          method: 'POST',
          body: formData
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Error ${response.status}: ${errorText}`);
        }

        const data = await response.json();
        let resultText = "Invalid response format";
        if (data.class !== undefined && data.score !== undefined) {
          const classDescription = data.class === 0 ? 'здоров' : 'болен';
          resultText = `${classDescription}, уверенность: ${Math.round(data.score * 100)}%`;
        }

        setUploadedImages(prevImages =>
          prevImages.map(image =>
            image.file === selectedFile ? { ...image, status: resultText } : image
          )
        );
      } catch (error) {
        console.error('Error fetching inference result:', error);
      } finally {
        setFileQueue(prevQueue => prevQueue.slice(1)); // delete queue file
        setIsProcessing(false);
      }
    };

    processQueue();
  }, [fileQueue, isProcessing]);

  return (
    <Container className="mt-3 text-center text-light">
      <div className="image-header mb-5">
        <Image src={project01BigCovid} className="img-fluid" />
        <h1 className="text-center mb-3">Выявление COVID-19</h1>
      </div>
      <Row>
        <Col>
          <p className="mt-3">Загрузите изображения КТ-сканирования легких, для автоматизированного выявления в организме человека вируса SARS-CoV-2</p>
          <p className="mt-3 text-gold">Нажмите кнопку "Выбрать файлы" и выберите один или несколько файлов изображений КТ-сканирования легких. Результат работы модели AML будет отображен на изображении через некоторое время после обработки</p>
          <p className="mt-3 text-gold">Новые файлы можно добавлять, не дожидаясь результата предыдущей загрузки</p>
        </Col>
      </Row>
      <Row className="mt-3">
        {uploadedImages.map((image, index) => (
          <Col key={index} md={4} className="mb-4">
            <div className="image-preview position-relative">
              <Image src={URL.createObjectURL(image.file)} fluid />
              {image.status === "Идет процесс обработки, ожидайте" && (
                <div className="processing-overlay">
                  <Image  src={processingGif} className="processing-gif" fluid />
                </div>
              )}
              <p className="image-status">{image.status !== "Идет процесс обработки, ожидайте" ? image.status : ''}</p>
            </div>
          </Col>
        ))}
      </Row>
      <Row className="mt-3">
        <Col>
          <Form.Control type="file" multiple accept=".png, .jpg" id="upload-image" onChange={(e) => {
            const files = Array.from(e.target.files);
            if (files.length > 0) {
              const newFiles = files.map(file => ({ file, status: "Идет процесс обработки, ожидайте" }));
              setFileQueue(prevQueue => [...prevQueue, ...newFiles]);
              setUploadedImages(prevImages => [...prevImages, ...newFiles]);
              console.log("Selected Files:", files);
            }
          }} />
        </Col>
      </Row>
    </Container>
  );
};

export default Covid;
