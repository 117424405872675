import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

const ProfileNotifications = () => {
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [userNotificationTypes, setUserNotificationTypes] = useState([]);
  const [userNotifications, setUserNotifications] = useState(false);
  const handleNotificationChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setNotificationTypes([...notificationTypes, value]);
    } else {
      setNotificationTypes(notificationTypes.filter(type => type !== value));
    }
  };

  const handleUserNotificationChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setUserNotificationTypes([...userNotificationTypes, value]);
    } else {
      setUserNotificationTypes(userNotificationTypes.filter(type => type !== value));
    }
  };

  const handleUserNotificationsChange = (e) => {
    setUserNotifications(e.target.checked);
  };

  const saveSettings = () => {
    console.log('Настройки уведомлений от AML сохранены:', notificationTypes);
    console.log('Настройки уведомлений от пользователей сохранены:', userNotificationTypes, 'Уведомления от пользователей:', userNotifications);
  };

  return (
    <>
      <Row className='bg-dark text-light'>
        <div className='text-light'>
          <h2>Уведомления</h2>
          <p className='opacity-75'>Выберите тип уведомления, который вы хотите получать от автоматического машинного обучения (AML) и от других пользователей.</p>
        </div>
      </Row>
      <Row className="profile-notifications-container bg-dark text-light">
        <Col sm={12}>
          <h3>Уведомления от AML</h3>
          <Form>
            <Form.Group controlId="formNotificationType">
              <Form.Check
                type="switch"
                label="Модель обучена"
                value="Модель обучена"
                checked={notificationTypes.includes("Модель обучена")}
                onChange={handleNotificationChange}
              />
              <Form.Check
                type="switch"
                label="Модель переобучена"
                value="Модель переобучена"
                checked={notificationTypes.includes("Модель переобучена")}
                onChange={handleNotificationChange}
              />
              <Form.Check
                type="switch"
                label="Исходные данные получены, началось обучение"
                value="Исходные данные получены, началось обучение"
                checked={notificationTypes.includes("Исходные данные получены, началось обучение")}
                onChange={handleNotificationChange}
              />
              <Form.Check
                type="switch"
                label="Ошибка данных"
                value="Ошибка данных"
                checked={notificationTypes.includes("Ошибка данных")}
                onChange={handleNotificationChange}
              />
            </Form.Group>
          </Form>
        </Col>
        <Col sm={12}>
          <h3>Уведомления от пользователей</h3>
          <Form>
            <Form.Group controlId="formUserNotifications">
              <Form.Check
                type="switch"
                label="Получать уведомления от других пользователей"
                checked={userNotifications}
                onChange={handleUserNotificationsChange}
              />
              <Form.Check
                type="switch"
                label="Новый комментарий"
                value="Новый комментарий"
                checked={userNotificationTypes.includes("Новый комментарий")}
                onChange={handleUserNotificationChange}
              />
              <Form.Check
                type="switch"
                label="Лайк"
                value="Лайк"
                checked={userNotificationTypes.includes("Лайк")}
                onChange={handleUserNotificationChange}
              />
              <Form.Check
                type="switch"
                label="Подписка"
                value="Подписка"
                checked={userNotificationTypes.includes("Подписка")}
                onChange={handleUserNotificationChange}
              />
              <Form.Check
                type="switch"
                label="Упоминание в комментарии"
                value="Упоминание в комментарии"
                checked={userNotificationTypes.includes("Упоминание в комментарии")}
                onChange={handleUserNotificationChange}
              />
              <Form.Check
                type="switch"
                label="Репост"
                value="Репост"
                checked={userNotificationTypes.includes("Репост")}
                onChange={handleUserNotificationChange}
              />
            </Form.Group>
          </Form>
        </Col>
        <Col sm={12}>
          <Button variant="primary" onClick={saveSettings}>Сохранить настройки</Button>
        </Col>
      </Row>
    </>
  );
};

export default ProfileNotifications;
