import React, { useState, useContext } from 'react';
import { AuthContext } from './userService';

const LoginComponent = () => {
    const { requestCode } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [status, setStatus] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleRequestCode = async () => {
        if (!email) {
            setError('Пожалуйста, введите email');
            return;
        }
        try {
            const response = await requestCode(email);
            setResponseMessage(response);
            setError(null);
        } catch (error) {
            setError('Произошла ошибка при отправке запроса: ' + error);
        }
    }

    return (
        <div>
            <h2>Вход существующего пользователя</h2>
            <input type="text" value={email} onChange={handleEmailChange} placeholder="Введите email" />
            <button onClick={handleRequestCode}>Войти</button>
            {error && <div>{error}</div>}
            {responseMessage && <div> responseMessage: {responseMessage}</div>}
        </div>
    );
};

export default LoginComponent;
