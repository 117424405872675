import React from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import pricingList from './../../helpers/pricingList'

const Billing = () => {
    const handleSubmit = (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity()) {
            console.log('Форма валидна. Выполняем отправку данных на сервер.');
        } else {
            console.log('Форма не валидна. Пожалуйста, заполните все обязательные поля.');
        }
    };
    // TODO secuirity
    const selectedPlan = pricingList[0];
    return (
        <Container className="mt-0 text-light bg-dark">
                <Row className="pt-5 m-5 mx-2 text-center border-bottom">
                <Col className="text-center">
                    <h1>Оплата выбранного тарифного плана:</h1>
                    <p className="opacity-75">Введите личные данные и способ оплаты *</p>
                </Col>
            </Row>

            <Row className="mt-3 ">
                <Col md={{ span: 4, offset: 4 }} className="d-flex justify-content-center">
                    <Card className="mb-4 rounded-3 shadow-sm text-light bg-dark">
                        <Card.Header className="py-3">
                            <h4 className="my-0 fw-normal text-center">{selectedPlan.name}</h4>
                        </Card.Header>
                        <Card.Body>
                            <Card.Title className="pricing-card-title text-gold">{selectedPlan.price} / month</Card.Title>
                            <ul className="list-unstyled mt-3 mb-4">
                                <li>{selectedPlan.description}</li>
                                <li>{selectedPlan.value1}</li>
                                <li>{selectedPlan.value2}</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>

            <Row className="g-5">
                <Col md={12} lg={12}>
                    <h4 className="mb-3">Реквизиты для выставления счетов</h4>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit}>
                        <Row className="g-3">
                            <Col sm={6}>
                                <Form.Group controlId="firstName">
                                    <Form.Label>Имя</Form.Label>
                                    <Form.Control type="text" placeholder="Иван" required />
                                    <Form.Control.Feedback type="invalid">Valid first name is required.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group controlId="lastName">
                                    <Form.Label>Фамилия</Form.Label>
                                    <Form.Control type="text" placeholder="Иванов" required />
                                    <Form.Control.Feedback type="invalid">Valid last name is required.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group controlId="username">
                                    <Form.Label>Имя пользователя</Form.Label>
                                    <div className="input-group has-validation">
                                        <span className="input-group-text">@</span>
                                        <Form.Control type="text" placeholder="пользователь" required />
                                        <Form.Control.Feedback type="invalid">Your username is required.</Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group controlId="email">
                                    <Form.Label>Электронная почта</Form.Label>
                                    <Form.Control type="email" placeholder="you@example.ru" required />
                                    <Form.Control.Feedback type="invalid">Please enter a valid email address for shipping updates.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group controlId="Address">
                                    <Form.Label>Адрес</Form.Label>
                                    <Form.Control type="text" placeholder="г. Москва, ул. Первая, д. 5, кв. 1" required />
                                    <Form.Control.Feedback type="invalid">Valid Address is required.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group controlId="Address2">
                                    <Form.Label>Адрес дополнительный (Не обязательно)</Form.Label>
                                    <Form.Control type="text" placeholder="" required />
                                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={5}>
                                <Form.Group controlId="country">
                                    <Form.Label>Страна</Form.Label>
                                    <Form.Select required>
                                        <option value="">Выбрать...</option>
                                        <option>Россия</option>
                                        <option>Китай</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a valid country.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="state">
                                    <Form.Label>Населенный пункт</Form.Label>
                                    <Form.Select required>
                                        <option value="">Выбрать...</option>
                                        <option>Москва</option>
                                        <option>Санкт-Петербург</option>
                                        <option>Ульяновск</option>

                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid state.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="zip">
                                    <Form.Label>Код почтового почты</Form.Label>
                                    <Form.Control type="text" placeholder="" required />
                                    <Form.Control.Feedback type="invalid">
                                        Код почтового отделения.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr className="my-4" />
                        <Row className="g-5">
                            <h4 className="mb-1">Оплата:</h4>

                                <Form.Check
                                    type="radio"
                                    id="credit"
                                    name="paymentMethod"
                                    label="Кредитная карта"
                                    checked
                                    onChange={() => { }}
                                    required
                                    className="mx-3"
                                />

                                <Form.Check
                                    type="radio"
                                    id="debit"
                                    name="paymentMethod"
                                    label="Дебетовая карта"
                                    onChange={() => { }}
                                    required
                                    className="mx-3"
                                />

                                <Form.Check
                                    type="radio"
                                    id="paypal"
                                    name="paymentMethod"
                                    label="СБП"
                                    onChange={() => { }}
                                    required
                                    className="mx-3"
                                />
           
                            <Row className="gy-3">
                                <Col md={6}>
                                    <Form.Group controlId="cc-name">
                                        <Form.Label>Имя на карте</Form.Label>
                                        <Form.Control type="text" placeholder="" defaultValue="" required />
                                        <Form.Label className="text-body-secondary">Full name as displayed on card</Form.Label>
                                        <Form.Control.Feedback type="invalid">Name on card is required.</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="cc-number">
                                        <Form.Label>Нормер счета на карте</Form.Label>
                                        <Form.Control type="text" placeholder="" defaultValue="" required />
                                        <Form.Control.Feedback type="invalid">Credit card number is required.</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group controlId="cc-expiration">
                                        <Form.Label>Срок действия</Form.Label>
                                        <Form.Control type="text" placeholder="" defaultValue="" required />
                                        <Form.Control.Feedback type="invalid">Expiration date required.</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group controlId="cc-cvv">
                                        <Form.Label>CVV</Form.Label>
                                        <Form.Control type="text" placeholder="" defaultValue="" required />
                                        <Form.Control.Feedback type="invalid">Security code required.</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Row>
                        <hr className="my-4" />
                        <Button variant="primary" size="lg" type="submit">
                            Оплатить
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Billing;
