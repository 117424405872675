import { useState, useContext } from 'react';
import { AuthContext } from './userService';

const UserRead = () => {
    const { getUserById } = useContext(AuthContext);
    const [userID, setUserID] = useState('');
    const [authorization, setAuthorization] = useState('');
    const [error, setError] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [status, setStatus] = useState('');

    const handleUserIDChange = (e) => {
        setUserID(e.target.value);
    };

    const handleAuthorizationChange = (e) => {
        setAuthorization(e.target.value);
    };

    const handleReadUser = async () => {
        if (!userID || !authorization) {
            setError('Пожалуйста, введите id и код');
            return;
        }
        try {
            const tokenData = { userID, authorization };
            const response = await getUserById(userID, authorization);
            setResponseMessage(response);
            setStatus(response);
            setError(null);
        } 
        catch (error) {
            if (error.response) {
                const { data, status, headers } = error.response
                console.error(data)
            } else if (error.request) {
                console.error(error.request)
            } else {
                console.error(error.message)
            }
            console.error(error.config)
            console.error(error.toJSON())
        }
    };

    return (
        <div>
            <h2>Чтение данных пользователя</h2>
            <label> User ID:</label>
            <input type="number" value={userID} onChange={handleUserIDChange} placeholder="Введите ID" />
            <label>Authorization:</label>
            <input type="text" value={authorization} onChange={handleAuthorizationChange} placeholder="Введите код" />
            <button onClick={handleReadUser}>Ок</button>
            <div>Статус: {status}</div>
            {error && <div>{error}</div>}
            {status && <div>Статус: {status} responseMessage: {responseMessage.data}</div>}
        </div>
    );
};

export default UserRead;
