import Project from "../project/Project";
import LeftMenu from "../project/LeftMenu";
import { projects } from "../../helpers/projectList";
import { leftMenuList } from "../../helpers/leftMenuList";
import { NavLink } from "react-router-dom";

const Projects = () => {
  const numberOfTrashedProjects = projects.reduce((count, project) => count + (project.isTrash ? 1 : 0), 0);
  return (
    <main className="container text-center text-light">
      {/* header */}
      <div className="row p-2 m-1 project__header">
        <div className="col-lg-3">
          <div className="d-flex p-2 align-items-center">
            <h2>Проекты</h2>
          </div>
        </div>
        <div className="col-md-auto">
          <div className="d-flex p-2 align-items-center">
            <svg className="bi " width="2em" height="2em">
              <use href="#folder-open"></use>
            </svg>
            <h2>Все: 55 проектов(а)</h2>
          </div>
        </div>
      </div>
      {/* content */}
      <div className="row">
        <div className="col-lg-3 mt-3 overflow-auto left__menu_bg">

          <div className="list-group scrollarea overflow-auto scrollbar-dark rounded-3">
            {leftMenuList.map((mList) => (
              <LeftMenu
                key={mList.id}
                projectName={mList.projectName}
                projectCount={mList.projectCount}
                URL={mList.URL}
              />
            ))}

            <div className="small text-light left__menu__drop_info">
              <p>
                Перетаскивайте проекты и папки, чтобы изменить их порядок. Перетаскивайте
                проекты на папки, чтобы добавить их в папку.
              </p>
            </div>

            <NavLink to="/projectTrash" className="list-group-item list-group-item-action d-flex gap-3 py-3 left__menu_item" aria-current="true">
              <div className="d-flex gap-2 w-100 justify-content-center">
                <div>
                  <h6 className="mb-0">
                    Удаленные проекты
                    <span className="mx-2"></span>
                    <span className="badge bg-secondary text-light">{numberOfTrashedProjects}</span>
                  </h6>
                </div>
              </div>
            </NavLink>
          </div>

        </div>
        {/* Projects */}
        <div className="col">
          <div className="row row-cols-1 row-cols-md-3 g-2 m-1">
            <div className="col">
              <div className="card card-body d-flex h-100 justify-content-center project__card_body">
                <NavLink to="/projectCreate" className="btn btn-primary">Создать новый проект...</NavLink>
              </div>
            </div>
            {projects
              .filter(project => !project.isTrash) //filtr project
              .map((project, index) => (
                <Project
                  key={project.id}
                  title={project.title}
                  img={project.img}
                  colorStatus={project.colorStatus}
                  dataPoz={project.dataPoz}
                  dataNeg={project.dataNeg}
                  index={index}
                />
              ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Projects;
