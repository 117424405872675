import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Container, Image } from 'react-bootstrap'
import Covid from './MedProjects/Covid';
import BG1 from './../../img/home/home0.jpg'
import Nocode from './../../img/home/Nocode.png'
import Useful from './../../img/home/useful.png'
import Image1 from './../../img/home/pause1.png'
import Image2 from './../../img/home/pause2.png'
import Image3 from './../../img/home/pause3.png'
import Image4 from './../../img/home/pause4.png'
import Fast from './../../img/home/fast.png'

const steps = [
    { id: 1, title: 'Создание проекта', image: Image1 },
    { id: 2, title: 'Загрузка данных', image: Image2 },
    { id: 3, title: 'Генерация модели', image: Image3 },
    { id: 4, title: 'Применение модели', image: Image4 },
  ];

const Home = () => {
    const [currentStep, setCurrentStep] = useState(steps[0]);
    return (
        <>
            <main className="container-fluid ">
                <div className="bg-container mb-5">
                    <img width="100%" height="auto" alt="" loading="lazy" decoding="async" data-nimg="1" className="" src={BG1} />
                    <div className="home__text" >
                        <Col md={12} className="text-light mb-5">
                            <h1 className="text-center mb-3">AML - простой путь в мир искусственного интеллекта</h1>
                            <p className="lead text-justify opacity-75">
                                AML позволяет любому использовать передовые технологии искусственного интеллекта — всего в несколько кликов. Все просто: загрузите примеры решённых задач, и AML построит модель, способную решать такие же задачи на новых данных.
                            </p>
                        </Col>
                    </div>
                </div>
                <div className="mb-5"> </div>
                <Container className="container mt-1 mb-5 p-4">
                    <Row className="justify-content-md-center body__bg_color text-light">
                        <Col md={4} className="ms-0 mt-5">
                            <Image src={Nocode} thumbnail className="mt-3" />
                            <h1 className="text-center mt-5">Просто</h1>
                            <p className="text-center  mt-5 opacity-75">
                                Для использования AML не требуется специальных знаний в области машинного обучения или программирования.
                                Достаточно загрузить данные, после чего сервис подготовит оптимальную модель автономно.
                            </p>
                        </Col>
                        <Col md={4} className="ms-0 mt-5">
                            <Image src={Fast} thumbnail className="mt-3" />
                            <h1 className="text-center mt-5">Быстро</h1>
                            <p className="text-center mt-5 opacity-75">
                                Благодаря использованию высокопроизводительных серверов в сочетании с
                                оптимизированными алгоритмами и современными распределенными технологиями,
                                AML обладает исключительным быстродействием.
                            </p>
                        </Col>
                        <Col md={4} className="ms-0 mt-5">
                            <Image src={Useful} thumbnail className="mt-3" />
                            <h1 className="text-center mt-5">Полезно</h1>
                            <p className="text-center mt-5 opacity-75">
                                Интеграция самых актуальных научных исследований и технологических решений позволяет
                                AML создавать точные и эффективные модели для самых сложных задач.
                            </p>
                        </Col>
                    </Row>
                </Container>

                <Container className="container mt-1 mb-5 p-4">
                    <Row className="justify-content-md-center body__bg_color text-light">
                        <Col md={12} className="mb-5">
                            <h1 className="text-center mb-3 text-light">
                                4 простых шага для начала работы с AML
                            </h1>
                        </Col>
                        <Col md={4} className="mb-5 mt-3">
                            {steps.map(step => (
                                <h2
                                    key={step.id}
                                    className={`mt-5 opacity-75 step-4 ${currentStep.id === step.id ? 'active-step' : ''}`}
                                    onClick={() => setCurrentStep(step)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {step.id}. {step.title}
                                </h2>
                            ))}
                        </Col>
                        <Col md={8}>
                            <Image src={currentStep.image} fluid />
                        </Col>
                    </Row>
                </Container>

                <h1 className="text-center mb-5 text-light">
                    Попробуйте один из проектов AML прямо сейчас!
                </h1>
                <Covid />
            </main>
        </>
    );
}

export default Home;
