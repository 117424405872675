import { useState, useContext, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Container, Form, Button, Row, Col, Alert, FormGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../services/userService';
import './rag.scss'

const defaultValues = {
    email: "",
};

const Registration = () => {
    const { createUser } = useContext(AuthContext);
    const { register, handleSubmit, formState: { errors } } = useForm(defaultValues);
    const [isSuccess, setIsSuccess] = useState(false);
    const [status, setStatus] = useState('');

    const onSubmit = async (data) => {
        try {
            const response = await createUser(data);
            setIsSuccess(true);
            console.log(isSuccess)
            setStatus(JSON.stringify(response))
        } catch (error) {
            console.error(error);
            setIsSuccess(false);
        }
    };

    return (
        <Container className="text-center mb-4 text-light bg-dark p-3">
            <Row className="justify-content-center">
                <h4 className="text-center mb-4">Регистрация</h4>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup controlId="formEmail" className="mb-3" isInvalid={errors.email}>
                        <Form.Control type="email" placeholder="Email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                        {errors.email && errors.email.type === "required" && (
                            <Form.Control.Feedback type="invalid">
                                Поле Email обязательно для заполнения
                            </Form.Control.Feedback>
                        )}
                        {errors.email && errors.email.type === "pattern" && (
                            <Form.Control.Feedback type="invalid">
                                Введенный Email некорректен
                            </Form.Control.Feedback>
                        )}
                    </FormGroup>
                    {isSuccess && ( // Показываем Alert при успешном состоянии
                        <Alert variant="success" className="mt-3">
                            Регистрация прошла успешно!  {status}
                            {status && <div>{status}1</div>}
                        </Alert>
                    )}
                    <Button type="submit">Зарегистрироваться</Button>
                </Form>
            </Row>
            <Row className="mt-3 text-center">
                <Col>
                    <p><NavLink to="/login">Авторизоваться</NavLink></p>
                </Col>
            </Row>
        </Container>
    );
};

export default Registration;
