import Project from "../project/Project";
import LeftMenu from "../project/LeftMenu";
import { projects } from "../../helpers/projectList";
import { leftMenuList } from "../../helpers/leftMenuList";
import { NavLink } from "react-router-dom";

const Projects = () => {
    const numberOfTrashedProjects = projects.reduce((count, project) => count + (project.isTrash ? 1 : 0), 0);
    const handleRestore = () => {
        console.log('Проект восстановлен');
    };

    const handleDelete = () => {
        console.log('Проект удален');
    };

    return (
        <main className="container text-center text-light">
            <div className="row">
                <div className="col-lg-3">
                    <div className="d-flex p-2 align-items-center">
                        <h2>Проекты</h2>
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="d-flex p-2 align-items-center">
                        <svg className="bi " width="2em" height="2em">
                            <use href="#folder-open"></use>
                        </svg>
                        <h2>В вашей корзине: {numberOfTrashedProjects} проекта/ов</h2>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3">
                    <div className="overflow-auto">
                        <div className="list-group scrollarea overflow-auto scrollbar-dark rounded-3">
                            {leftMenuList.map((mList) => (
                                <LeftMenu
                                    key={mList.id}
                                    projectName={mList.projectName}
                                    projectCount={mList.projectCount}
                                    URL={mList.URL}
                                />
                            ))}

                            <div className="bd-callout bd-callout-info">
                                <p>
                                    Drag and drop projects and folders to reorder them. Drag and
                                    drop projects onto folders to add them to a folder.
                                </p>
                            </div>

                            <NavLink to="/projectTrash"
                                className="list-group-item list-group-item-action d-flex gap-3 py-3"
                                aria-current="true">

                                <div className="d-flex gap-2 w-100 justify-content-center">
                                    <div>
                                        <h6 className="mb-0">
                                            Удаленные проекты
                                            <span className="badge mx-3 bg-secondary text-light">{numberOfTrashedProjects}</span>
                                        </h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="py-5 bg-body-tertiary bg-dark">
                        <div className="container">
                            <div className="row row-cols-1 row-cols-md-3 g-4">
                                {projects
                                    .filter(project => project.isTrash) // Фильтрация проектов
                                    .map((project, index) => (
                                        <div key={project.id}>
                                            <Project
                                                title={project.title}
                                                img={project.img}
                                                colorStatus={project.colorStatus}
                                                dataPoz={project.dataPoz}
                                                dataNeg={project.dataNeg}
                                                index={index}
                                            />
                                            <div className="btn-group mt-3" role="group" aria-label="Basic example">
                                                <button type="button" className="btn btn-secondary rounded" onClick={() => handleRestore(project.id)}>
                                                    Восстановить
                                                </button>
                                                <span className="mx-2"></span>
                                                <button type="button" className="btn btn-danger rounded" onClick={() => handleDelete(project.id)}>
                                                    Удалить
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Projects;
