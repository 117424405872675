import { useState, useContext } from 'react';
import { AuthContext } from './userService';
import config from './config';

const ProjectDelete = () => {
    const { handleProjectDelete } = useContext(AuthContext);
    const [name, setName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [error, setError] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [status, setStatus] = useState('');
    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleReadUser = async () => {
        if (!name) {
            setError('Пожалуйста, введите id код и email');
            return;
        }

        try {
            const response = await handleProjectDelete(name);
            setResponseMessage(response);
            setStatus(response);
            setError(null);
        } catch (error) {
            if (error.response) {
                const { data, status, headers } = error.response;
                console.error(data);
            } else if (error.request) {
                console.error(error.request);
            } else {
                console.error(error.message);
            }
            console.error(error.config);
            console.error(error.toJSON());
        }
    };

    return (
        <div>
            <h2>Удилать проект</h2>
            <input type="text" value={name} onChange={handleNameChange} placeholder="Введите id проекта" />
            <button onClick={handleReadUser}>Ок</button>
            <div>Статус: {status}</div>
            {error && <div>{error}</div>}
        </div>
    );
};

export default ProjectDelete;
