import { useState, useContext } from 'react';
import { AuthContext } from './userService';
import config from './config';

const UserUpdate = () => {
    const { updateUserById } = useContext(AuthContext);
    const [userID, setUserID] = useState('');
    const [authorization, setAuthorization] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [error, setError] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [status, setStatus] = useState('');
    const handleUserIDChange = (e) => {
        setUserID(e.target.value);
    };

    const handleAuthorizationChange = (e) => {
        setAuthorization(e.target.value);
    };

    const handleNewEmailChange = (e) => {
        setNewEmail(e.target.value);
    };

    const handleReadUser = async () => {
        if (!userID || !authorization || !newEmail) {
            setError('Пожалуйста, введите id и код и email');
            return;
        }

        try {
            const response = await updateUserById(userID, authorization, newEmail);
            setResponseMessage(response);
            setStatus(response);
            setError(null);
        } catch (error) {
            if (error.response) {
                const { data, status, headers } = error.response;
                console.error(data);
            } else if (error.request) {
                console.error(error.request);
            } else {
                console.error(error.message);
            }
            console.error(error.config);
            console.error(error.toJSON());
        }
    };

    const handleGetLocalStorageValue = () => {
        const savedValue = localStorage.getItem(config.TOKEN);;
        if (savedValue !== null) {
            setStatus(savedValue);
        } else {
            setError('Значение не найдено в LocalStorage');
        }
    };

    return (
        <div>
            <h2>Обновить почту пользователя</h2>
            <label> User ID:</label>
            <input type="number" value={userID} onChange={handleUserIDChange} placeholder="Введите ID" />
            <label>Authorization:</label>
            <input type="text" value={authorization} onChange={handleAuthorizationChange} placeholder="Введите код" />
            <label>Email:</label>
            <input type="text" value={newEmail} onChange={handleNewEmailChange} placeholder="Введите email" />
            <button onClick={handleReadUser}>Ок</button>
            <div>Статус: {status}</div>
            {error && <div>{error}</div>}
            <button onClick={handleGetLocalStorageValue}>Получить значение из LocalStorage</button>
        </div>
    );
};

export default UserUpdate;
