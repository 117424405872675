import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';

const ProfileBilling = () => {
  const balance = 500;

  return (
    <>
      <Row className='text-dark'>
        <div className='text-light'>
          <h2>Платежная история</h2>
          <p className='opacity-75'>Информация о совершенных платежах и сохраненных картах на вашем аккаунте.</p>
        </div>
      </Row>
      <Row className="profile-billing-container bg-dark text-light">
        <Col sm={12}>
          <h3>Текущий баланс</h3>
          <p>Ваш текущий баланс: ${balance}</p>
        </Col>
        <Col sm={12}>
          <h3>Совершенные платежи</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Дата</th>
                <th>Сумма</th>
                <th>Статус</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>12.03.2024</td>
                <td>$50</td>
                <td>Оплачено</td>
              </tr>
              <tr>
                <td>2</td>
                <td>11.03.2024</td>
                <td>$30</td>
                <td>Оплачено</td>
              </tr>
              <tr>
                <td>3</td>
                <td>10.03.2024</td>
                <td>$20</td>
                <td>Оплачено</td>
              </tr>
              <tr>
                <td>4</td>
                <td>09.03.2024</td>
                <td>$25</td>
                <td>Оплачено</td>
              </tr>
              <tr>
                <td>5</td>
                <td>08.03.2024</td>
                <td>$40</td>
                <td>Оплачено</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col sm={12}>
          <h3>Сохраненные карты</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Тип карты</th>
                <th>Номер карты</th>
                <th>Срок действия</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Visa</td>
                <td>**** **** **** 1234</td>
                <td>03/26</td>
              </tr>
              <tr>
                <td>2</td>
                <td>MasterCard</td>
                <td>**** **** **** 5678</td>
                <td>05/27</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default ProfileBilling;
