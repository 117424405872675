import { useState } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import pricingList from '../../helpers/pricingList';

const PricingEdit = () => {
  const [plans, setPlans] = useState(pricingList);

  const handleEdit = (index) => {
    const updatedPlans = [...plans];
    updatedPlans[index].isEditing = true;
    setPlans(updatedPlans);
  };

  const handleSave = (index) => {
    const updatedPlans = [...plans];
    updatedPlans[index].isEditing = false;
    setPlans(updatedPlans);
  };

  const handleCancel = (index) => {
    const updatedPlans = [...plans];
    updatedPlans[index].isEditing = false;
    setPlans(updatedPlans);
  };

  const handleInputChange = (index, field, value) => {
    const updatedPlans = [...plans];
    updatedPlans[index][field] = value;
    setPlans(updatedPlans);
  };

  return (
    <Container className="mt-3">
      <Row>
        <Col>
          <h2>Pricing Edit</h2>
        </Col>
      </Row>

      <Row className="mt-3">
        {plans.map((plan, index) => (
          <Col md={4} key={plan.id}>
            <Card>
              <Card.Header as="h5">
                {plan.isEditing ? (
                  <Form.Control
                    type="text"
                    value={plan.name}
                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                  />
                ) : (
                  plan.name
                )}
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  {plan.isEditing ? (
                    <Form.Control
                      type="text"
                      value={plan.price}
                      onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                    />
                  ) : (
                    plan.price
                  )}
                </Card.Title>
                <Card.Text>
                  {plan.isEditing ? (
                    <Form.Control
                      as="textarea"
                      value={plan.description}
                      onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                    />
                  ) : (
                    plan.description
                  )}
                </Card.Text>
                {plan.isEditing ? (
                  <>
                    <Button variant="success" onClick={() => handleSave(index)}>
                      Save
                    </Button>
                    <Button variant="danger" onClick={() => handleCancel(index)}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button variant="primary" onClick={() => handleEdit(index)}>
                    Edit
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default PricingEdit;
