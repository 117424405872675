import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

const LeftMenu = ({ projectName, projectCount, URL }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedProjectName, setEditedProjectName] = useState(projectName);
  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    setEditedProjectName(e.target.value);
  };

  const handleSave = () => {
    console.log('Saved:', editedProjectName);
    toggleEditing();
  };

  return (
    <div>
      <div className="list-group-item list-group-item-action d-flex gap-3 py-3 left__menu_item">
        <div className="d-flex gap-2 w-100 justify-content-center ">
          <div className="">
            <h6 className="mb-0">{projectName}</h6>
            {!isEditing && (
              <p className="mb-0 opacity-75">{projectCount} проектов(а)</p>
            )}
          </div>
          <div className="ms-auto">
            <Button
              variant="link"
              onClick={toggleEditing}
              style={{ textDecoration: 'none' }}
            >
              {isEditing ? (
                'Отменить'
              ) : (
                <>
                  <span>Изменить</span>{' '}
                  <span className="visually-hidden">Изменить</span>
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
      {isEditing && (
        <div className="list-group-item d-flex justify-content-center left__menu_edit">
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="Edit project name"
              value={editedProjectName}
              onChange={handleInputChange}
            />
            <button className="btn btn-primary mt-2" onClick={handleSave}>
              Сохранить
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeftMenu;
