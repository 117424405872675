import { useState, useContext } from 'react';
import { AuthContext } from './userService';
import config from './config';

const ProjectCreate = () => {
    const { handleProjectCreate } = useContext(AuthContext);
    const [name, setName] = useState('');
    const [domain_id, setDomainId] = useState('');
    const [type_id, setTypeId] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [error, setError] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [status, setStatus] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleDomainId = (e) => {
        setDomainId(e.target.value);
    };

    const handleTypeId = (e) => {
        setTypeId(e.target.value);
    };




    const handleReadUser = async () => {
        if (!name) {
            setError('Пожалуйста, введите id код и email');
            return;
        }

        try {
            const response = await handleProjectCreate(name, domain_id, type_id);
            setResponseMessage(response);
            setStatus(response);
            setError(null);
        } catch (error) {
            if (error.response) {
                const { data, status, headers } = error.response;
                console.error(data);
            } else if (error.request) {
                console.error(error.request);
            } else {
                console.error(error.message);
            }
            console.error(error.config);
        }
    };


    return (
        <div>
            <h2>Создать проект</h2>
             <input type="text" value={name} onChange={handleNameChange} placeholder="Введите наименование проекта" />
            <input type="text" value={domain_id} onChange={handleDomainId} placeholder="Введите DomainId" />
            <input type="text" value={type_id} onChange={handleTypeId} placeholder="Введите TypeId" /> 
            <button onClick={handleReadUser}>Создать проект</button>
            <div>Статус: {status}</div>
            {error && <div>{error}</div>}
        </div>
    );
};

export default ProjectCreate;
