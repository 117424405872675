import React from 'react';
import { useState, useContext } from 'react';
import { Container, Navbar, Nav, Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaHome, FaProjectDiagram, FaMoneyBill, FaAddressBook, FaCog, FaUser, FaSignOutAlt, FaBell, FaComment, FaSearch, FaSignInAlt } from 'react-icons/fa';
import { Button, Modal, Form } from 'react-bootstrap';
import ThemeToggle from './ThemeToggle';
import ScrollToTopButton from '../../utils/ScrollToTopButton';
import './style.css';
import logo from './../../swg/GraviLinkFull.svg';
import logoAML from './../../img/logoAML.png';
import Avatar from './../../img/avatar1.png';
import { AuthContext } from '../../services/userService';

const Header = () => {
    const { isUserLogged, handleLogOut } = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <header className="p-3 mb-3">
            <ScrollToTopButton />
            <Navbar expand="md" variant="dark" fixed="top">
                <Container>
                    <NavLink to="/" className="navbar-brand">
                        <img id="company logo" alt="company logo" loading="lazy" width="180" height="24" decoding="async" data-nimg="1" className="d-flex align-items-center" src={logoAML} />
                    </NavLink>
                    <Navbar.Toggle aria-controls="navbarCollapse" />
                    <Navbar.Collapse id="navbarCollapse" className="justify-content-end">
                        <Nav className="me-md-3 text-center d-flex flex-column flex-md-row align-items-md-center">
                            <Nav.Link as={NavLink} to="/" className="mb-1 me-md-1">
                                <FaHome className="me-2" />
                                <strong>Главная</strong>
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/projects" className="mb-1 me-md-1">
                                <FaProjectDiagram className="me-2" />
                                <strong>Панель управления</strong>
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/pricing" className="mb-1 me-md-1">
                                <FaMoneyBill className="me-2" />
                                <strong>Подписка</strong>
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/contacts" className="mb-1 me-md-1">
                                <FaAddressBook className="me-2" />
                                <strong>Контакты</strong>
                            </Nav.Link>
                        </Nav>

                        <Nav className="ms-auto" style={{ marginLeft: 'auto' }}>
                            <Nav.Link onClick={handleShowModal} className="mb-1 me-md-1 text-center d-flex flex-column flex-md-row align-items-md-center">
                                <FaSearch className="me-1" />
                                <strong>Поиск</strong>
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/notifications" className="mb-1 me-md-1">
                                <FaBell />
                            </Nav.Link>
                            <Nav.Link href="#" className="mb-1 me-md-1">
                                <FaComment />
                            </Nav.Link>
                            {!isUserLogged && (
                                <Nav.Link as={NavLink} to="/login" className="mb-1 me-md-1 text-center d-flex flex-column flex-md-row align-items-md-center">
                                    <FaSignInAlt className="me-1" />
                                    <strong>Войти</strong>
                                </Nav.Link>
                            )}
                        </Nav>

                        {/* <ThemeToggle /> */}
                        {isUserLogged && (
                            <Dropdown align="end">
                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                    <img
                                        src={Avatar} //TODO set source image
                                        alt="Avatar"
                                        width="32"
                                        height="32"
                                        className="rounded-circle"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="bg-dark text-light">
                                    <Dropdown.Item href="/profile" className="text-light">
                                        <FaUser className="me-2" />
                                        Изменить профиль
                                    </Dropdown.Item>
                                    <Dropdown.Item href="../projects" className="text-light">
                                        <FaProjectDiagram className="me-2" />
                                        Новый проект...
                                    </Dropdown.Item>
                                    <Dropdown.Divider className="bg-light text-light" />
                                    <Dropdown.Item onClick={handleLogOut} href="/" className="text-light">
                                        <FaSignOutAlt className="me-2" />
                                        Выход
                                        {console.log("handleLogOut")}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}

                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/*search modal form*/}
            <div>
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-light">Искать:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="search">
                                <Form.Control type="text" placeholder="Введите текст для поиска" />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>Закрыть</Button>
                        <Button variant="primary">Найти</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        </header>
    );
};

export default Header;
