import { useState, useContext } from 'react';
import { AuthContext } from './userService';

const RegisterComponent = () => {
    const { createUser } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [status, setStatus] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleCreateUser = async () => {
        if (!email) {
            setError('Пожалуйста, введите email');
            return;
        }
        try {
            const userData = { email };
            const response = await createUser(userData);
            setResponseMessage(response);
            //setStatus(response.status); 
            setError(null);
        } 
        catch (error) {
            setError('Произошла ошибка при создании пользователя: ' + error);
        }
    };

    return (
        <div>
            <h2>Регистрация нового пользователя</h2>
            <input type="text" value={email} onChange={handleEmailChange} placeholder="Введите email" />
            <button onClick={handleCreateUser}>Зарегистрировать</button>
            {error && <div>{error}</div>}
            {status && <div>Статус: {status} ответ: {responseMessage}</div>}
        </div>
    );
};

export default RegisterComponent;
