import { Button, Form } from 'react-bootstrap';

const ProjectCreate = () => {
  const handleSelectAll = (type) => {
  };

  const handleDeleteSelected = (type) => {
  };

  return (
    <main className="container text-light bg-dark p-3">
      <div className="container text-left ">
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex p-2 align-items-center">
              <h2>Новый проект</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <Form>
              {/* Project Details */}
              <div className="mb-3">
                <Form.Label>
                  Название проекта:
                </Form.Label>
                <Form.Control type="text" placeholder="Введите название проекта" />
              </div>

              <div className="mb-3">
                <Form.Label>
                  Описание проекта:
                </Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Введите описание проекта" />
              </div>

              <div className="mb-3">
                <Form.Label>
                  Выберите предопределенное поведение:
                </Form.Label>
                <Form.Select>
                  <option value="standard">по умолчанию</option>
                  <option value="model1">Model 1</option>
                  <option value="model2">Model 2</option>
                </Form.Select>
              </div>

              <div className="mb-3">
                <Form.Label>
                  Выберите папку проекта:
                </Form.Label>
                <Form.Select>
                  <option value="folder1">Все проекты</option>
                  <option value="folder2">Медицина</option>
                </Form.Select>
              </div>

            </Form>
          </div>

          {/* GalleryPositive Data */}
          <div className="col-md-6">
            <Form>
              <div className="mb-3">
                <Form.Label>
                  Загрузите изображения (Истинные данные):
                </Form.Label>
                <Form.Control type="file" multiple />
              </div>

              {/* Gallery Images for Positive Data */}
              <div className="mt-3">
                <Button variant="primary" onClick={() => handleSelectAll('Positive')}>
                  Выбрать все
                </Button>
                <span className="mx-2"></span>
                <Button variant="secondary" onClick={() => handleDeleteSelected('Positive')}>
                  Удалить выбранные
                </Button>
              </div>
            </Form>
          </div>

          {/* Right Column: Negative Data */}
          <div className="col-md-6">
            <Form>
              <div className="mb-3">
                <Form.Label>
                  Загрузите изображения (Ложные данные):
                </Form.Label>
                <Form.Control type="file" multiple />
              </div>

              {/* Gallery Images for Negative Data */}
              <div className="mt-3">
                <Button variant="primary" onClick={() => handleSelectAll('Negative')}>
                  Выбрать все
                </Button>
                <span className="mx-2"></span>
                <Button variant="secondary" onClick={() => handleDeleteSelected('Negative')}>
                  Удалить выбранные
                </Button>
              </div>
            </Form>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col text-center">
            <Button variant="primary" type="submit">
              Создать проект
            </Button>
            <span className="mx-2"></span>
            <Button variant="secondary">Отменить</Button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProjectCreate;
